/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Table } from 'react-bootstrap';
import {
  SearchAlt2,
  RightArrowAlt,
  LeftArrowAlt,
} from 'styled-icons/boxicons-regular';

export const TabelaEstoque = styled(Table)`
  padding: 20px;
  margin-top: 10px;
  border-top: 0 none;
  border-left: 0 none;
  border-right: 0 none;
  width: 100%;
  text-align: center;
  vertical-align: middle;

  .theadFixed {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: var(--white);
  }
`;

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  padding: 30px;
  .table {
    width: 100% !important;
  }
`;

export const CircleColor = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 8px;
  border: 0.5px solid black;
  background-color: ${(props) => props.color};
`;

export const SearchIcon = styled(SearchAlt2)`
  width: 25px;
  height: 25px;
  color: white;
`;

export const NextIcon = styled(LeftArrowAlt)`
  width: 25px;
  height: 25px;
  color: white;
`;

export const PreviousIcon = styled(RightArrowAlt)`
  width: 25px;
  height: 25px;
  color: white;
`;
export const DivTabela = styled.div`
  width: 100%;
  border-radius: 20px;
  margin-top: 20px;
  overflow: auto;
  max-height: calc(100vh - 220px);
  &::-webkit-scrollbar {
    display: block;
    height: 10px;
    background-color: var(--tablestriped);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--outline);
    border-radius: 10px;
  }
`;
