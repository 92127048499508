import styled from 'styled-components';
import { FileText } from 'styled-icons/bootstrap';
import { Dropdown } from 'react-bootstrap';

export const NfNotification = styled.button`
  display: ${(props) => props.display};
  background-color: var(--danger);
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50px;
  border: 0 none;
  left: 65%;
  top: -8px;
  color: var(--white);
  font-size: 10px;
  font-weight: bolder;
`;

export const NfIcon = styled(FileText)`
  width: 10px;
  height: 10px;
`;

export const MobileItem = styled(Dropdown.Item)`
  display: none;
  text-align: center;
  width: 100vw;
  @media (max-width: 700px) {
    display: table-cell;
  }
`;
