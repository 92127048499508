/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
import './Sidebar.css';
import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Pdf from '../../assets/Documents/manual.pdf';
import { AuthContext } from '../../contexts/auth';
import {
  SideBarButton,
  CartIcon,
  SketchIcon,
  StorageIcon,
  ChartIcon,
  HelpIcon,
  LogoutIcon,
  CloseIcon,
  AvatarIcon,
} from './styles';
import SidebarStatus from '../../contexts/sidebarContext';

// exportando SidebarOpen/closeSidebar da Home.jsx
function Sidebar({ sidebarOpen, closeSidebar }) {
  // declarando Logout
  const { logout } = useContext(AuthContext);
  const [gerente, setGerente] = useState(false);
  const idGerente = localStorage.getItem('id_gerente');
  const tpUser = localStorage.getItem('tp_user');
  const [sidebarStatus, setSidebarStatus] = useContext(SidebarStatus);

  const nav = useNavigate();

  console.log(idGerente);

  const user = localStorage.getItem('user');

  const renderTooltip = ({ name, ...props }) => (
    <Tooltip id="button-tooltip" {...props}>
      {name}
    </Tooltip>
  );

  useEffect(() => {
    if (idGerente > 0 || tpUser > 2) {
      setGerente(true);
    }
    let i = 0;

    while (i < 6) {
      if (i === 3) {
        break;
      }
      i += 1;
    }
  }, []);
  // eslint-disable-next-line no-unused-vars
  const handleLogout = () => {
    logout();
  };

  const newLocal = '_blank';

  return (
    <div className={sidebarStatus ? 'sidebar-responsive' : ''} id="sidebar">
      <div
        style={{
          display: 'flex',
          paddingTop: '20px',
          paddingRight: '20px',
          justifyContent: 'right',
        }}
      >
        <CloseIcon
          onClick={() => {
            setSidebarStatus((state) => !state);
          }}
        />
      </div>
      <div className="sidebar__avatar">
        <AvatarIcon>{user ? user[1] : null}</AvatarIcon>
      </div>
      <OverlayTrigger
        placement="right"
        delay={{ show: 100, hide: 400 }}
        overlay={renderTooltip({ name: 'Pedidos' })}
      >
        <SideBarButton
          color="#8F5FE8"
          onClick={() => {
            nav('/Pedidos');
          }}
        >
          <CartIcon />
        </SideBarButton>
      </OverlayTrigger>
      <OverlayTrigger
        placement="right"
        delay={{ show: 100, hide: 400 }}
        overlay={renderTooltip({ name: 'Rascunho' })}
      >
        <SideBarButton
          color="#FFAB00"
          onClick={() => {
            nav('/rascunho');
          }}
        >
          <SketchIcon />
        </SideBarButton>
      </OverlayTrigger>
      {/* <OverlayTrigger
        placement="right"
        delay={{ show: 100, hide: 400 }}
        overlay={renderTooltip({ name: 'Estoque' })}
      >
        <SideBarButton
          color="#17A2B8"
          onClick={() => {
            nav('/estoque');
          }}
        >
          <StorageIcon />
        </SideBarButton>
      </OverlayTrigger> */}
      {idGerente > 0 || tpUser > 2 ? (
        <OverlayTrigger
          placement="right"
          delay={{ show: 100, hide: 400 }}
          overlay={renderTooltip({ name: 'Dashboard' })}
        >
          <SideBarButton
            color="#02C758"
            onClick={() => {
              nav('/graficos');
            }}
          >
            <ChartIcon />
          </SideBarButton>
        </OverlayTrigger>
      ) : null}
      <a href={Pdf} target={newLocal}>
        <OverlayTrigger
          placement="right"
          delay={{ show: 0, hide: 400 }}
          overlay={renderTooltip({ name: 'Ajuda' })}
        >
          <SideBarButton color="#0090E7">
            <HelpIcon />
          </SideBarButton>
        </OverlayTrigger>
      </a>
      <OverlayTrigger
        placement="right"
        delay={{ show: 100, hide: 400 }}
        overlay={renderTooltip({ name: 'Logout' })}
      >
        <SideBarButton onClick={logout} color="#FC424A">
          <LogoutIcon />
        </SideBarButton>
      </OverlayTrigger>
    </div>
  );
}

export default Sidebar;
