/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import HashLoader from 'react-spinners/PulseLoader';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api/axios';

function VerificarPedCli() {
  const [itens, setItens] = useState([]);
  const [pagina, setPagina] = useState(1);
  const id_rep = localStorage.getItem('idrep');
  const verificacao = localStorage.getItem('pedcliente');
  const clie = JSON.parse(localStorage.getItem('clicomp'));
  const [verificador, setVerificador] = useState(0);
  const rollback = useNavigate();

  let cliente = '';

  if (clie) {
    cliente = clie[2];
  }

  useEffect(() => {
    api
      .get(`ListaPrePedidos?pagina=${pagina}&id_rep=${id_rep}&busca=${cliente}`)
      .then(async (response) => {
        await setTimeout(() => {
          setItens(response.data);
        }, 500);
      })
      .catch((error) => {
        console.log('erro ao receber lista de pedidos');
      });
  }, [cliente, id_rep, pagina]);

  const pedidosCli = itens.map((pedido) => pedido.PEDIDOCLIENTE);

  if (pedidosCli.length < 1 && verificador < 1) {
    rollback('/Addpedido');
  }

  function paginacao() {
    if (pedidosCli.length > 1) {
      setPagina(pagina + 1);
    }
  }

  function verifica() {
    if (pedidosCli.includes(verificacao.trim())) {
      setVerificador(1);
    }
  }

  function navigate() {
    rollback('/Addpedido');
  }

  setTimeout(() => {
    paginacao();
    verifica();
  }, 500);

  return (
    <div>
      <div className="loading">
        <p style={{ marginRight: '10px' }}>
          Verificando o pedido: <strong>{verificacao}</strong>
        </p>
        <HashLoader type="spin" color="black" size={10} speedMultiplier={0.9} />
        {verificador > 0 ? (
          <SweetAlert
            warning
            customButtons={
              <button
                type="button"
                src="./itempedido.css"
                className="buttonalert__confirm"
                onClick={navigate}
              >
                OK
              </button>
            }
          >
            Pedido do cliente ja utilizado!
          </SweetAlert>
        ) : null}
      </div>
    </div>
  );
}

export default VerificarPedCli;
