/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-expressions */
export function Porcentagem(numero1, numero2) {
  let result = 0;
  numero1 > numero2
    ? (result = ((numero1 - numero2) / numero2) * 100)
    : (result = ((numero2 - numero1) / numero1) * 100);

  if (isNaN(result)) {
    return '0%';
  }
  return `${new Intl.NumberFormat('pt-BR', {
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(result)}%`;
}
