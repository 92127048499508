/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import './AppRoutes.css';
import React, { useContext, useState } from 'react';
import HashLoader from 'react-spinners/HashLoader';
import { AuthContext, AuthProvider } from '../contexts/auth';
import UserLogin from '../pages/Login';
import Order from '../components/order/Pedidos/Order';
import OrderPageAdd from '../pages/OrderPageAdd';
import Orderpageitem from '../pages/Orderpageitem';
import Ordernewitempage from '../pages/Orderpagenewitem';
import SearchTranspPage from '../pages/searchTranspPage';
import BuscarCondPgtoPage from '../pages/searchCond';
import BuscarProdPage from '../pages/searchProduto';
import BuscarRepresentPage from '../pages/searchRepresentate';
import BuscarTipoPage from '../pages/searchTipo';
import BuscarFormaPgtoPage from '../pages/searchFormadePagamento';
import BuscarClientePage from '../pages/searchCliente';
import ConsultaPedPage from '../pages/Consulta';
import ConsultaItemPage from '../pages/ConsultaItem';
import PDF from '../components/Pdf/Pdf';
import BuscarClienteEntrPage from '../pages/searchClienteEntr';
import BuscarClienteFatPage from '../pages/searchClienteFat';
import Graficos from '../pages/Graficos';
import GraficoVenda from '../pages/GraficosVendas';
import VendasporNome from '../pages/VendaspoNome';
import FatporNome from '../pages/FatporNome';
import GraficoAnterior from '../pages/GraficoAnterior';
import FatporNomeAnt from '../pages/FatporNomeAnt';
import GraficoVendasAnterior from '../pages/GraficoVendasAnt';
import VendasporNomeAnterior from '../pages/VendaspoNomeAnt';
import Duplicarpe from '../components/order/Duplicarpedido';
import VerificarPedCli from '../components/order/VerificarPedCli';
import RascunhoPage from '../pages/RascunhoPage';
import SalvarRascunho from '../components/order/SalvarRascunho';
import PdfRascunho from '../components/Pdf/PdfRascunho';
import Estoque from '../pages/Estoque';
import Sidebar from '../components/sidebar/Sidebar';
import Navbar from '../components/navbar/Navbar';
import { GlobalStyle } from '../styles/globalStyles';
import SidebarStatus from '../contexts/sidebarContext';
import Busca from '../contexts/Busca';
import Refresh from '../contexts/Refresh';
import FaturamentoNome from '../components/graficos/faturamentoNome/faturamentoNome';
import Username from '../contexts/User';

function AppRoutes() {
  const [sidebarStatus, setSidebarStatus] = useState(false);
  const [search, setSearch] = useState('');
  const [refresh, setRefresh] = useState(0);
  const [visible, setVisible] = useState(true);
  const [user, setUser] = useState(localStorage.getItem('user'));

  let tamanhoLoading = '';
  if (window.matchMedia('(min-width:900px)').matches) {
    tamanhoLoading = 150;
  } else {
    tamanhoLoading = 80;
  }

  function Private({ children }) {
    const { authenticated, loading } = useContext(AuthContext);
    if (loading) {
      return (
        <div className="loading">
          <HashLoader
            color="black"
            size={tamanhoLoading}
            className="loading"
            speedMultiplier={1.2}
          />
        </div>
      );
    }
    if (!authenticated) {
      setVisible(true);
      return <Navigate to="/" />;
    }
    setVisible(false);

    return children;
  }

  return (
    <Router>
      <AuthProvider>
        <GlobalStyle />
        {/* declarando as rotas */}
        <Routes>
          <Route exact path="/" element={<UserLogin />} />
        </Routes>
        {/* redes privada */}
        <div
          className="cotainerHomePage"
          style={{ display: visible ? 'none' : '' }}
        >
          <Busca.Provider value={[search, setSearch]}>
            <Refresh.Provider value={[refresh, setRefresh]}>
              <Username.Provider value={[user, setUser]}>
                <SidebarStatus.Provider
                  value={[sidebarStatus, setSidebarStatus]}
                >
                  <Sidebar />
                  <Navbar />
                </SidebarStatus.Provider>
                <div style={{ gridArea: 'main', width: '100%' }}>
                  <Routes>
                    <Route
                      exact
                      path="/Pedidos"
                      element={
                        <Private>
                          <div style={{ width: '100%' }}>
                            <Order />
                          </div>
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/Addpedido"
                      element={
                        <Private>
                          <OrderPageAdd />
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/Itensdopedido"
                      element={
                        <Private>
                          <Orderpageitem />
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/Novoitem"
                      element={
                        <Private>
                          <Ordernewitempage />
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/buscartransp"
                      element={
                        <Private>
                          <SearchTranspPage />
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/buscacondpgto"
                      element={
                        <Private>
                          <BuscarCondPgtoPage />
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/buscaprodutos"
                      element={
                        <Private>
                          <BuscarProdPage />
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/buscarepresentante"
                      element={
                        <Private>
                          <BuscarRepresentPage />
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/buscartipopedido"
                      element={
                        <Private>
                          <BuscarTipoPage />
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/buscarformapgto"
                      element={
                        <Private>
                          <BuscarFormaPgtoPage />
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/buscarcliente"
                      element={
                        <Private>
                          <BuscarClientePage />
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/buscarclienteentr"
                      element={
                        <Private>
                          <BuscarClienteEntrPage />
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/buscarclientefat"
                      element={
                        <Private>
                          <BuscarClienteFatPage />
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/consultapage/:id"
                      element={
                        <Private>
                          <ConsultaPedPage />
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/consultaitem/:id"
                      element={
                        <Private>
                          <ConsultaItemPage />
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/pdf/:id"
                      element={
                        <Private>
                          <PDF />
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/graficos"
                      element={
                        <Private>
                          <Graficos />
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/graficoVendas"
                      element={
                        <Private>
                          <GraficoVenda />
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/vendas/:id"
                      element={
                        <Private>
                          <VendasporNome />
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/faturamento/:id"
                      element={
                        <Private>
                          <FatporNome />
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/graficomesanterior"
                      element={
                        <Private>
                          <GraficoAnterior />
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/faturamentoanterior/:id"
                      element={
                        <Private>
                          <FatporNomeAnt />
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/graficovendasanterior"
                      element={
                        <Private>
                          <GraficoVendasAnterior />
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/vendasmesanterior/:id"
                      element={
                        <Private>
                          <VendasporNomeAnterior />
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/duplicarped/:id"
                      element={
                        <Private>
                          <Duplicarpe />
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/verificarpedcli"
                      element={
                        <Private>
                          <VerificarPedCli />
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/rascunho"
                      element={
                        <Private>
                          <RascunhoPage />
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/salvarascunho"
                      element={
                        <Private>
                          <SalvarRascunho />
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/pdfrascunho"
                      element={
                        <Private>
                          <PdfRascunho />
                        </Private>
                      }
                    />
                    <Route
                      exact
                      path="/estoque"
                      element={
                        <Private>
                          <Estoque />
                        </Private>
                      }
                    />
                  </Routes>
                </div>
              </Username.Provider>
            </Refresh.Provider>
          </Busca.Provider>
        </div>
      </AuthProvider>
    </Router>
  );
}

export default AppRoutes;
