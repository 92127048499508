import styled from 'styled-components';
import { RightArrowAlt, CartAdd, Trash } from 'styled-icons/boxicons-regular';

export const Container = styled.div`
  width: 100%;
  padding: 20px;
  .table {
    width: 100% !important;
  }
`;

export const RigthIcon = styled(RightArrowAlt)`
  width: 25px;
  height: 25px;
`;

export const LeftIcon = styled(RightArrowAlt)`
  width: 25px;
  height: 25px;
  transform: scaleX(-1) scaleY(-1);
`;

export const CartAddIcon = styled(CartAdd)`
  width: 20px;
  height: 20px;
`;

export const TrashIcon = styled(Trash)`
  width: 20px;
  height: 20px;
`;

export const InputQuant = styled.input`
  text-align: center;
  border-top: 0 none;
  border-left: 0 none;
  border-right: 0 none;
  border-bottom: 1px solid var(--bs-gray-600);
  outline: 0 none;
`;
