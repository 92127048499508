/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-use-before-define */
import React, { useContext, useState } from 'react';
import md5 from 'md5-hash';
import { AuthContext } from '../contexts/auth';
import './Login.css';
import jpIMG from '../assets/img/logo-Branyl.jpg';

function UserLogin() {
  const { login } = useContext(AuthContext);
  // pegando informações do input
  const [usuario, setUsuario] = useState('');
  const [senha, setSenha] = useState('');
  const [conectado, setConectado] = useState(false);
  const [checador, setChecador] = useState(false);

  const verificarpagina = window.location.pathname;
  // login
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!conectado === false) {
      localStorage.setItem('check', conectado);
    }
    // quebrando a string em uma arrey ate o @
    const usuarioFormatado = usuario.split('@');
    // passando os dados antes do @ e passando o usuario e a senha para md5
    login(md5(usuarioFormatado[0]), md5(senha)); // integração com o context
    if (verificarpagina === '/' || verificarpagina === '/#') {
      check();
    }
  };

  function check() {
    setTimeout(() => setChecador(true), 1000);
  }

  function checkBoxStyle() {
    if (conectado === false) {
      document.getElementById('flexCheckText').style.color = 'black';
      document.getElementById('flexCheckText').style.fontWeight = 500;
    } else {
      document.getElementById('flexCheckText').style.color = '#adadad';
      document.getElementById('flexCheckText').style.fontWeight = 'normal';
    }
  }

  return (
    <div className="container">
      <div className="container-login">
        <div className="wrap-login">
          <form className="login-form" onSubmit={handleSubmit}>
            <span className="login-form-tittle">
              <img src={jpIMG} alt="Branyl" />
              {checador ? (
                <p className="aviso">
                  <i className="fa fa-exclamation-triangle" />
                  Email ou senha invalidos
                </p>
              ) : null}
            </span>
            <div className="wrap-input">
              {/* pegando os dados do placeholder */}
              <input
                className={usuario !== '' ? 'has-val input' : 'input'}
                type="email"
                value={usuario}
                onChange={(e) => setUsuario(e.target.value)}
              />
              <span className="focus-input" data-placeholder="E-mail" />
            </div>
            <div className="wrap-input1">
              {/* pegando os dados do placeholder */}
              <input
                className={senha !== '' ? 'has-val input' : 'input'}
                type="password"
                name="password"
                autoComplete="off"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
              />
              <span className="focus-input" data-placeholder="Password" />
            </div>
            <div className="testee">
              <div className="wrap-checkbox">
                <input
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  onChange={checkBoxStyle}
                  onClick={() => {
                    document.getElementById('flexCheckDefault').checked
                      ? setConectado(true)
                      : setConectado(false);
                  }}
                />
                <label id="flexCheckText" htmlFor="flexCheckDefault">
                  Manter conectado
                </label>
              </div>
            </div>
            <div className="container-login-form-btn">
              <button className="login-form-btn" type="submite">
                Login
              </button>
            </div>
          </form>
          <p>Desenvolvido por T.I Branyl</p>
        </div>
      </div>
    </div>
  );
}

export default UserLogin;
