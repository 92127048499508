import styled from 'styled-components';
import { Menu, RightArrowAlt } from 'styled-icons/boxicons-regular';
import { BackInTime } from 'styled-icons/entypo';

export const NavBarTop = styled.div`
  background: #262526;
  grid-area: nav;
  height: calc(100% + 20px);
  display: flex;
  align-items: center;
  padding: 0 30px 0 30px;
  color: white;

  @media only screen and (max-width: 1000px) {
    height: calc(100% + 20px);
  }
`;

export const MenuIcon = styled(Menu)`
  display: none;
  width: 25px;
  height: 25px;
  color: white;

  @media only screen and (max-width: 1000px) {
    display: flex;
  }
`;

export const RightArrowIcon = styled(RightArrowAlt)`
  width: 25px;
  height: 25px;
`;

export const BackTime = styled(BackInTime)`
  width: 20px;
  height: 20px;
`;
