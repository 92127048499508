/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-lone-blocks */
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Button, Table } from 'react-bootstrap';
import {
  Container,
  LeftIcon,
  CartAddIcon,
  InputQuant,
  TrashIcon,
} from './styles';
import './itempedido.css';

function ItemPedido() {
  const [excluir, setExcluir] = useState(false);
  const [itemped, setItemped] = useState(0);
  const nave = useNavigate();

  let contador = 1;

  const LocalItensDoPedido = JSON.parse(localStorage.getItem('jsonitens'));

  let ItensDoPedido =
    localStorage.getItem('jsonitens') !== null ? LocalItensDoPedido : [];

  function RemoveItem(reduzido) {
    ItensDoPedido = ItensDoPedido.filter((item) => item.REDUZIDO !== reduzido);
    localStorage.setItem('jsonitens', JSON.stringify(ItensDoPedido));
    localStorage.removeItem('dtentrega');
    setExcluir(false);
  }

  function trocarpFloat(value) {
    if (typeof value === 'string') {
      const valorRep = value.replace(',', '.');
      const valorFloat = parseFloat(valorRep);
      return valorFloat;
    }
  }

  function formatarDinheiro(value) {
    const valorRep = value.replace('.', ',');
    return valorRep;
  }

  function go() {
    setTimeout(() => nave('/Addpedido'), 100);
    setTimeout(() => nave('/Itensdopedido'), 110);
  }

  function alterQuantidade(posicao, value) {
    ItensDoPedido[posicao].QTDE = value;
    localStorage.setItem('jsonitens', JSON.stringify(ItensDoPedido));
    go();
  }

  return (
    <div className="containerMain">
      <div className="mainitempedidos">
        <div className="itempedidomain1">
          <div className="orderitem__butons2">
            <Button
              type="button"
              id="button-addon2"
              onClick={() => nave('/Addpedido')}
              style={{ width: '120px', height: '40px' }}
            >
              <LeftIcon />
            </Button>
            <Link to="/buscaprodutos">
              <Button
                onClick={localStorage.removeItem('descrum')}
                type="button"
                id="button-addon2"
                variant="success"
                style={{ width: '120px', height: '40px' }}
              >
                <span
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginRight: '2px',
                  }}
                >
                  Add Prod.
                </span>
                <CartAddIcon
                  style={{ display: 'inline-block', verticalAlign: 'middle' }}
                />
              </Button>
            </Link>
          </div>
        </div>
        <Container>
          <Table style={{ textAling: 'center', verticalAlign: 'middle' }}>
            {ItensDoPedido.length > 0 ? (
              <thead>
                <tr>
                  <th />
                  <th>Item</th>
                  <th>Cod.</th>
                  <th>Descrição</th>
                  <th>U.M</th>
                  <th>Largura</th>
                  <th>Quantidade</th>
                  <th>Valor Unitario</th>
                  <th>Total Item</th>
                  <th>Observação</th>
                </tr>
              </thead>
            ) : null}
            <tbody>
              {ItensDoPedido.map((item) => (
                <tr key={item.REDUZIDO}>
                  <td>
                    {/* <Link
                      to="#"
                      onClick={() => {
                        setItemped(item.REDUZIDO);
                        setExcluir(true);
                      }}
                    > */}
                    <Button
                      variant="danger"
                      onClick={() => {
                        setItemped(item.REDUZIDO);
                        setExcluir(true);
                      }}
                      style={{
                        position: 'relative',
                        width: '35px',
                        height: '35px',
                      }}
                    >
                      <TrashIcon
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',

                          transform: 'translate(-50%, -50%)',
                        }}
                      />
                    </Button>
                    {/* </Link> */}
                  </td>
                  <td>{contador++}</td>
                  <td>{item.REDUZIDO}</td>
                  <th>{item.DESCRICAO}</th>
                  <th scope="row">{item.DESCR_UM}</th>
                  <th scope="row">{item.LARGURA}</th>
                  <td>
                    <InputQuant
                      type="text"
                      defaultValue={formatarDinheiro(item.QTDE)}
                      onBlur={(e) => {
                        alterQuantidade(
                          ItensDoPedido.indexOf(item),
                          e.target.value.replace(',', '.'),
                        );
                      }}
                    />
                  </td>
                  <td>
                    {parseFloat(item.PRECO).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </td>
                  <td>
                    {/* R${' '}
                    {new Intl.NumberFormat('pt-BR', {
                      currency: 'BRL',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(
                      trocarpFloat(item.PRECO) * trocarpFloat(item.QTDE),
                    )} */}
                    {(
                      parseFloat(item.PRECO) * parseFloat(item.QTDE)
                    ).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </td>
                  <td>{item.OBS}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {ItensDoPedido.length > 0 ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'start',
              }}
            >
              <Link to="/Addpedido">
                <Button variant="success" type="button" id="button-addon2">
                  <i className="fa fa-check-circle" /> Confirmar
                </Button>
              </Link>
            </div>
          ) : null}
        </Container>
        {/* Alerta para confirmação da exclusão do Item */}
        {excluir ? (
          <SweetAlert
            danger
            showCancel
            title="Excluir o item !"
            onConfirm={() => RemoveItem(itemped)}
            onCancel={() => {
              setExcluir(false);
            }}
            customButtons={
              <>
                <button
                  type="button"
                  src="./itempedido.css"
                  className="buttonalert__confirm"
                  onClick={() => RemoveItem(itemped)}
                >
                  <i className="fa fa-check-circle-o" />
                  <span> Confirmar </span>
                </button>
                <button
                  type="button"
                  src="./itempedido.css"
                  className="buttonalert__cancel"
                  onClick={() => {
                    setExcluir(false);
                  }}
                >
                  <i className="fa fa-ban" /> Cancelar
                </button>
              </>
            }
          >
            Deseja remover o reduzido {itemped} do pedido ?
          </SweetAlert>
        ) : null}
      </div>
    </div>
  );
}

export default ItemPedido;
