import { Button } from 'react-bootstrap';
import { useContext } from 'react';
import { RefreshIcon } from './styles';
import Refresh from '../../contexts/Refresh';

export default function RefreshButton() {
  const [refresh, setRefresh] = useContext(Refresh);

  return (
    <Button
      variant="warning"
      style={{
        width: '50px',
        height: '40px',
        position: 'relative',
        marginRight: '10px',
      }}
      onClick={() => setRefresh((state) => state + 1)}
    >
      <RefreshIcon
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    </Button>
  );
}
