import React, { useState, useContext } from 'react';
import { Search, SearchText, FormControl, SearchIcon } from './styles.js';
import Busca from '../../contexts/Busca.js';

function SearchBar() {
  const [isFormControlFocused, setIsFormControlFocused] = useState(false);
  const [search, setSearch] = useContext(Busca);

  return (
    <Search className="d-flex">
      <SearchText
        bgColor={isFormControlFocused ? '#363436' : '#262526'}
        bdColor={isFormControlFocused ? '#FFFFFF' : '#858385'}
      >
        <SearchIcon color={isFormControlFocused ? '#FFFFFF' : '#858385'} />
      </SearchText>
      <FormControl
        type="search"
        placeholder="Pesquisar por nome, pedido, ordem ..."
        className="me-2"
        aria-label="Search"
        onFocus={() => setIsFormControlFocused(true)}
        onBlur={() => setIsFormControlFocused(false)}
        onChange={(e) => setSearch(e.target.value)}
      />
    </Search>
  );
}

export default SearchBar;
