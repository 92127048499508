/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable camelcase */
import './Order.css';
import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/core.css';
import { ClipLoader } from 'react-spinners';
import Status from '../Status';
import LimparDados from '../LimparDados';
import ScrollReturn from '../../buttons/scrollReturn';
import api from '../../../services/api/axios';
import {
  Container,
  TabelaPedido,
  StatusIcon,
  ContainerLegend,
  DivTabela,
  ContainerMain,
  CogIcon,
  EyesIcon,
  DuplicateIcon,
  PdfIcon,
  NoOrder,
} from './styles';
import StatusButton from '../../Status';
import Busca from '../../../contexts/Busca';
import Refresh from '../../../contexts/Refresh';

function Order() {
  const [pedidos, setPedidos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [scrollRatio, setScrollRatio] = useState(null);
  const [pagina, setPagina] = useState(1);
  const [search, setSearch] = useContext(Busca);
  const [refreshKey, setRefreshKey] = useContext(Refresh);
  const [final, setFinal] = useState(false);
  const [noOrders, setNoOrders] = useState(false);
  const id_rep = localStorage.getItem('idrep');
  const [error, setError] = useState('');
  const scrollObserv = useRef();

  const intersectionObserver = new IntersectionObserver((entries) => {
    const ratio = entries[0].intersectionRatio;
    setScrollRatio(ratio);
  });

  useEffect(() => {
    intersectionObserver.observe(scrollObserv.current);

    return () => {
      intersectionObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (scrollRatio > 0) {
      setPagina(pagina + 1);

      if (
        id_rep !== '0' &&
        id_rep !== null &&
        id_rep !== undefined &&
        id_rep !== ''
      ) {
        api
          .get(
            `ListaPrePedidos?pagina=${pagina}&id_rep=${id_rep}&busca=${search}`,
          )
          .then(async (response) => {
            await setPedidos([...pedidos, ...response.data]);

            if (response.data.length > 0) {
              setFinal(true);
              setScrollRatio(0.45);
            } else {
              setFinal(false);
              setNoOrders(true);
            }

            setLoading(false);
          })
          .catch((error) => {
            console.log('erro ao receber lista de pedidos XXX');
          });

        return;
      }

      setLoading(false);
      setError(
        'Erro ao receber lista de pedidos, tente novamente! Se nescessario, efetue o login novamente.',
      );
    }
  }, [scrollRatio, refreshKey]);

  useEffect(() => {
    if (refreshKey >= 1) {
      setPagina(1);
    }
    if (
      id_rep !== '0' &&
      id_rep !== null &&
      id_rep !== undefined &&
      id_rep !== ''
    ) {
      api
        .get(
          `ListaPrePedidos?pagina=1&itens=30&id_rep=${id_rep}&busca=${search}`,
        )
        .then(async (response) => {
          await setPedidos(response.data);
          if (response.data.length > 0) {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log('erro ao receber lista de pedidos');
        });
      return;
    }

    setLoading(false);
    setError(
      'Erro ao receber lista de pedidos, tente novamente! Se nescessario, efetue o login novamente.',
    );
  }, [search, refreshKey]);

  return (
    <ContainerMain>
      <div style={{ margin: '0 auto', width: '100%' }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'end',
            alignItems: 'center',
          }}
        >
          <ContainerLegend>
            <StatusIcon color="#019efe" />
            DIGITADO
          </ContainerLegend>
          <ContainerLegend>
            <StatusIcon color="#dc143c" />
            CANCELADO
          </ContainerLegend>
          <ContainerLegend>
            <StatusIcon color="#008000" />
            LIBERADO
          </ContainerLegend>
          <ContainerLegend>
            <StatusIcon color="#0000ff" />
            CONCLUIDO
          </ContainerLegend>
          <ContainerLegend>
            <StatusIcon color="#ff7f50" />
            AG. LIBERAÇÃO
          </ContainerLegend>
        </div>
        <Container>
          <DivTabela>
            <TabelaPedido striped responsive style={{ textAlign: 'center' }}>
              <thead>
                <tr>
                  <th>Opções</th>
                  <th>Cliente</th>
                  <th>Pre-Pedido</th>
                  <th>Pedido Final</th>
                  <th className="desktopSize">Ordem Cliente</th>
                  <th>Emissão</th>
                  <th>Valor</th>
                  <th className="desktopSize">Status</th>
                  <th className="mobileSize">:</th>
                </tr>
              </thead>
              <tbody>
                {pedidos.map((pedido) => (
                  <tr key={pedido.PRE_PEDIDO}>
                    <td>
                      <Menu
                        menuButton={
                          <MenuButton className="config">
                            <CogIcon />
                          </MenuButton>
                        }
                        display="arrow"
                        align="start"
                        direction="right"
                        viewScroll="auto"
                        position="anchor"
                      >
                        <Link to={`/consultapage/${pedido.PRE_PEDIDO}`}>
                          <MenuItem style={{ width: '200px', margin: '10px' }}>
                            <button
                              className="buttontable"
                              type="button"
                              style={{ verticalAlign: 'middle' }}
                            >
                              <EyesIcon color="purple" /> Visualizar Pedido
                            </button>
                          </MenuItem>
                        </Link>
                        <Link to={`/duplicarped/${pedido.PRE_PEDIDO}`}>
                          <MenuItem style={{ width: '200px', margin: '10px' }}>
                            <button
                              className="buttontable"
                              type="button"
                              onClick={LimparDados}
                            >
                              <DuplicateIcon color="warning" /> Duplicar Pedido
                            </button>
                          </MenuItem>
                        </Link>
                        <Link to={`/pdf/${pedido.PRE_PEDIDO}`}>
                          <MenuItem style={{ width: '200px', margin: '10px' }}>
                            <button className="buttontable" type="button">
                              <PdfIcon color="danger" /> Gerar PDF
                            </button>
                          </MenuItem>
                        </Link>
                      </Menu>
                    </td>
                    <td>{pedido.CLIENTE}</td>
                    <th scope="row">{pedido.PRE_PEDIDO}</th>
                    <th scope="row">{pedido.PEDIDO_SGT}</th>
                    <th scope="row" className="desktopSize">
                      {pedido.PEDIDOCLIENTE}
                    </th>
                    <td>{pedido.DIGITACAO}</td>
                    <td>
                      {pedido.TOTAL
                        ? pedido.TOTAL.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })
                        : null}
                    </td>
                    <td>
                      <StatusButton
                        status={Status(pedido.SITUACAO).text}
                        nf={pedido.NF}
                        emissao={pedido.EMISSAO}
                        bgColor={Status(parseInt(pedido.SITUACAO)).hex}
                        pedidoCliente={pedido.PEDIDOCLIENTE}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </TabelaPedido>
            <NoOrder display={noOrders ? 'flex' : 'none'}>
              Você não possui pedidos!
            </NoOrder>
            {/* Declarando o scrollObserv para quando chegar ao final da planilha carregar + paginas */}
            <div
              ref={scrollObserv}
              style={{
                width: '100%',
                height: '20px',
                marginTop: final ? '10%' : '0%',
              }}
            >
              <span
                style={{
                  display: final ? 'flex' : 'none',
                  verticalAlign: 'center',
                  textAlign: 'center',
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ClipLoader
                  type="spin"
                  color="black"
                  size={50}
                  className="loading"
                  speedMultiplier={1.2}
                />
              </span>
            </div>
          </DivTabela>
        </Container>
        <span
          style={{
            width: '100%',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {error}
        </span>
        <div
          style={{
            display: loading ? 'flex' : 'none',
            width: '100%',
            height: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ClipLoader
            type="spin"
            color="black"
            size={50}
            className="loading"
            speedMultiplier={1.2}
          />
        </div>
        <ScrollReturn position={500} />
      </div>
    </ContainerMain>
  );
}

export default Order;
