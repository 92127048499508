import styled, { css } from 'styled-components';
import { Table } from 'react-bootstrap';
import { Cog, FilePdf, Duplicate } from 'styled-icons/boxicons-solid';
import { RemoveRedEye } from 'styled-icons/material';

export const Container = styled.div`
  margin-top: 35px;
  background-color: #ffffff;
  width: 100%;

  .table {
    width: 100% !important;
  }

  .mobileSize {
    display: none;
  }
  @media (max-width: 700px) {
    .desktopSize {
      display: none;
    }
    .mobileSize {
      display: table-cell;
    }
  }
`;

const iconCss = css`
  width: 20px;
  height: 20px;
  color: var(--${(props) => props.color});
`;

export const TabelaPedido = styled(Table)`
  border-top: 0 none;
  border-left: 0 none;
  border-right: 0 none;
  border-style: none;
  width: 100%;
  text-align: center;

  font-size: 1rem;

  td {
    vertical-align: middle;
  }

  th {
    vertical-align: middle;
  }

  @media (max-width: 700px) {
    font-size: 1px;
  }

  @media only screen and (max-width: 1366px) {
    font-size: 0.9rem;
  }

  .theadFixed {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: var(--white);
  }
`;

export const ContainerMain = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 0 none;
`;

export const ContainerLegend = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  margin-left: 10px;

  @media only screen and (max-width: 1366px) {
    font-size: 0.8rem;
  }
`;

export const StatusIcon = styled.button`
  width: 15px;
  height: 15px;
  background-color: ${(props) => props.color};
  border-radius: 5px;
  border: 0 none;
  margin-right: 5px;
`;

export const DivTabela = styled.div`
  width: 100%;
  border-radius: 20px;
  margin-top: 20px;
  overflow: auto;
  max-height: calc(100vh - 200px);
  &::-webkit-scrollbar {
    display: block;
    height: 10px;
    background-color: var(--tablestriped);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--outline);
    border-radius: 10px;
  }
`;

export const CogIcon = styled(Cog)`
  width: 25px;
  height: 25px;
  color: black;
  @media (max-width: 700px) {
    width: 20px;
    height: 20px;
  }
`;

export const EyesIcon = styled(RemoveRedEye)`
  ${iconCss}
`;

export const DuplicateIcon = styled(Duplicate)`
  ${iconCss}
`;

export const PdfIcon = styled(FilePdf)`
  ${iconCss}
`;

export const NoOrder = styled.div`
  display: ${(props) => props.display};
  flex: 1;
  justify-content: center;
  padding: 2rem;
`;
