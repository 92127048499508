/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-shadow */
/* eslint-disable new-cap */
/* eslint-disable no-promise-executor-return */
/* eslint-disable import/order */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api, { apiNode } from '../../services/api/axios';
import './pdf.css';
import jsPDF from 'jspdf';
import Vi1 from './vi_1.png';
import Vi2 from './vi_2.png';
import Vi3 from './vi_3.png';
import Vi4 from './vi_4.png';
import Vi5 from './vi_5.png';
import Vi6 from './vi_6.png';
import Vi7 from './vi_7.png';
import Vi8 from './vi_8.png';
import Vi9 from './vi_9.png';
import Vi10 from './vi_10.png';
import Vi11 from './vi_11.png';
import Vi12 from './vi_12.png';
import Vi13 from './vi_13.png';
import Vi14 from './vi_14.png';
import Vi15 from './vi_15.png';
import Vi16 from './vi_16.png';
import jpIMG from '../../assets/img/logo-Branyl.jpg';
// eslint-disable-next-line no-unused-vars
import dompurify from 'dompurify';
// eslint-disable-next-line no-unused-vars
import html2canvas from 'html2canvas';
import HashLoader from 'react-spinners/ClipLoader';
import SweetAlert from 'react-bootstrap-sweetalert';

function PDF() {
  const navigate = useNavigate();
  const date = new Date().toLocaleString();
  const [dados, setDados] = useState([]);
  const [itens, setItens] = useState([]);
  const [loading, setLoading] = useState(false);
  const [complementOrders, setComplementOrders] = useState([]);

  // importando o endpoint da URL
  const { id } = useParams();
  function generatePDF(nomepdf) {
    const doc = new jsPDF('l', 'pt', 'a3');
    doc.html(document.querySelector('#pagina'), {
      callback: () => {
        doc.save(`pedido ${nomepdf}`);
        navigate('/Pedidos');
      },
    });
  }

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    api.get(`DetalhesPedidoItens?pedido=${id}`).then(async (resultado) => {
      await setItens(resultado.data);
    });
    api.get(`DetalhesPedido?pedido=${id}`).then(async (resultado) => {
      await setDados(resultado.data);
      setLoading(true);
      await apiNode
        .get('client', {
          params: { idPessoaFj: resultado.data[0].IDFILIALENTREGA },
        })
        .then(async (response) => {
          await setComplementOrders(response.data);
        });
      await delay(1000);
      generatePDF(resultado.data.map((dados) => dados.NUMERO_PRE_PEDIDO));
    });
  }, []);

  function veririfcarnull(value) {
    let verificarvalue = '';
    if (value === 'null') {
      verificarvalue = ' ';
    } else {
      verificarvalue = value;
    }
    return verificarvalue;
  }

  function trocaDePed(value, value2) {
    let pedido = '';
    let pedidoNumber = '';
    if (value === null) {
      pedidoNumber = value2;
      pedido = 'Pré Pedido :';
    } else {
      pedidoNumber = value;
      pedido = 'Pedido :';
    }

    return { pedidoNumber, pedido };
  }

  const valorTotal = itens
    .map((item) => item.QUANTIDADE_DIGITACAO * item.PRECOUNITARIO)
    .reduce((prev, curr) => prev + curr, 0);

  function alterarData(dataEmissao) {
    const dataArray = dataEmissao.toString().split('');
    const dataEmissaFormat = `${dataArray[6] + dataArray[7]}/${
      dataArray[4] + dataArray[5]
    }/${dataArray[0] + dataArray[1] + dataArray[2] + dataArray[3]} `;
    return dataEmissaFormat;
  }

  function formatarCNPJ(CNPJ) {
    const CNPJArray = CNPJ.toString().split('');
    const cnpjFormat = `${CNPJArray[0] + CNPJArray[1]}.${
      CNPJArray[2] + CNPJArray[3] + CNPJArray[4]
    }.${CNPJArray[5] + CNPJArray[6] + CNPJArray[7]}/${
      CNPJArray[8] + CNPJArray[9] + CNPJArray[10] + CNPJArray[11]
    }-${CNPJArray[12] + CNPJArray[13]} `;
    return cnpjFormat;
  }

  function formatCEP(cep) {
    return cep.replace(/^(\d{5})(\d{3})$/, '$1-$2');
  }

  return (
    <div>
      {loading === true ? (
        <div>
          <SweetAlert
            customButtons={<></>}
            style={{ position: 'absolute', top: '2.99in', left: '3.65in' }}
          >
            <div>
              <HashLoader
                color="black"
                size={50}
                className="loading"
                speedMultiplier={1.2}
              />
            </div>
            Gerando PDF
          </SweetAlert>
        </div>
      ) : null}
      {dados.map((dados) => (
        <div key={dados.NUMERO_PRE_PEDIDO} id="pagina" className="pdfmain">
          <img
            style={{
              position: 'absolute',
              top: '0.21in',
              left: '0.43in',
              width: '11.91in',
              height: '2.37in',
            }}
            src={Vi1}
          />
          <img
            src={jpIMG}
            style={{
              position: 'absolute',
              top: '0.25in',
              left: '0.46in',
              width: '0.98in',
              height: '1.13in',
            }}
          />
          <div
            style={{
              position: 'absolute',
              top: '0.34in',
              left: '1.45in',
              width: '1.56in',
              lineHeight: '0.16in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              Branyl Com e Ind Textil Ltda
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '0.59in',
              left: '1.45in',
              width: '1.75in',
              lineHeight: '0.16in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              R. Flavio Giacomini, S N
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '0.84in',
              left: '1.45in',
              width: '1.81in',
              lineHeight: '0.16in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              CEP: 13363 - 160 Capivari - SP
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '1.09in',
              left: '1.45in',
              width: '1.55in',
              lineHeight: '0.16in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              Fone:(55) (19) 3492-8400
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '1.37in',
              left: '0.46in',
              width: '2.52in',
              lineHeight: '0.22in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              Escritório Comercial
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '1.57in',
              left: '0.47in',
              width: '2.71in',
              lineHeight: '0.16in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              Av. Brig. Faria Lima, 1912 conj. 19J - Jd Paulistano
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '1.78in',
              left: '0.47in',
              width: '3.00in',
              lineHeight: '0.18in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              CEP 01452-922 - São Paulo - SP
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              Fone: (55) (19) 3492-8400{' '}
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '2.18in',
              left: '0.47in',
              width: '1.77in',
              lineHeight: '0.16in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              Email: comercial@branyl.com.br
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '1.39in',
              left: '3.59in',
              width: '1.21in',
              lineHeight: '0.17in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '8pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              CLIENTE ENTREGA:
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '8pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              Cliente:
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '0.52in',
              left: '3.59in',
              width: '0.72in',
              lineHeight: '0.19in',
            }}
          >
            <div style={{ position: 'relative', left: '0.01in' }}>
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: '8pt',
                  fontFamily: 'Verdana',
                  color: '#000000',
                }}
              >
                Cliente:
              </span>
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: '8pt',
                  fontFamily: 'Verdana',
                  color: '#000000',
                }}
              >
                {' '}
              </span>
              <br />
            </div>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              Endereço:
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              CNPJ:
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '1.12in',
              left: '3.59in',
              width: '0.52in',
              lineHeight: '0.17in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              E-mail:
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '1.77in',
              left: '3.59in',
              width: '0.72in',
              lineHeight: '0.18in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              Endereço:
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              CNPJ:
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '2.19in',
              left: '3.59in',
              width: '0.52in',
              lineHeight: '0.17in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              E-mail:
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <img
            style={{
              position: 'absolute',
              top: '0.22in',
              left: '3.48in',
              width: '0.02in',
              height: '2.15in',
            }}
            src={Vi2}
          />
          <img
            style={{
              position: 'absolute',
              top: '1.31in',
              left: '3.48in',
              width: '8.47in',
              height: '0.02in',
            }}
            src={Vi3}
          />
          <img
            style={{
              position: 'absolute',
              top: '0.22in',
              left: '10.36in',
              width: '1.98in',
              height: '0.21in',
            }}
            src={Vi4}
          />
          <img
            style={{
              position: 'absolute',
              top: '0.21in',
              left: '10.35in',
              width: '1.99in',
              height: '0.23in',
            }}
            src={Vi5}
          />
          <div
            style={{
              position: 'absolute',
              top: '0.25in',
              left: '10.43in',
              width: '0.87in',
              lineHeight: '0.17in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '8pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              Data Emissão:
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '8pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <img
            style={{
              position: 'absolute',
              top: '0.43in',
              left: '10.36in',
              width: '1.98in',
              height: '0.30in',
            }}
            src={Vi6}
          />
          <img
            style={{
              position: 'absolute',
              top: '0.43in',
              left: '10.35in',
              width: '1.99in',
              height: '0.31in',
            }}
            src={Vi7}
          />
          <div
            style={{
              position: 'absolute',
              top: '0.50in',
              left: '10.42in',
              width: '0.88in',
              lineHeight: '0.22in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#0000ff',
              }}
            >
              {trocaDePed(dados.PEDIDO, dados.NUMERO_PRE_PEDIDO).pedido}
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#0000ff',
              }}
            />
            <br />
          </div>
          <img
            style={{
              position: 'absolute',
              top: '0.73in',
              left: '10.36in',
              width: '1.98in',
              height: '1.63in',
            }}
            src={Vi8}
          />
          <img
            style={{
              position: 'absolute',
              top: '0.73in',
              left: '10.35in',
              width: '1.99in',
              height: '1.64in',
            }}
            src={Vi9}
          />
          <div
            style={{
              position: 'absolute',
              top: '0.83in',
              left: '10.42in',
              width: '1.03in',
              lineHeight: '0.19in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '9pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              Pedido Repres:
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '9pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '0.82in',
              left: '11.37in',
              width: '1.00in',
              lineHeight: '0.21in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {dados.PEDIDOREPRESENTANTE}
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '10pt',
                fontFamily: 'Verdana',
                color: '#0000ff',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '1.12in',
              left: '10.42in',
              width: '1.03in',
              lineHeight: '0.19in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '9pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              Pedido Cliente:
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '9pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '1.11in',
              left: '11.37in',
              width: '1.00in',
              lineHeight: '0.21in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {dados.PEDIDOCLIENTE}
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '10pt',
                fontFamily: 'Verdana',
                color: '#0000ff',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '1.63in',
              left: '10.54in',
              width: '1.67in',
              lineHeight: '0.19in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '9pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              Condição de Pagamento:
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '9pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <img
            style={{
              position: 'absolute',
              top: '1.32in',
              left: '10.35in',
              width: '1.99in',
              height: '0.01in',
            }}
            src={Vi10}
          />
          <img
            style={{
              position: 'absolute',
              top: '2.36in',
              left: '0.43in',
              width: '11.91in',
              height: '0.01in',
            }}
            src={Vi11}
          />
          <div
            style={{
              position: 'absolute',
              top: '1.86in',
              left: '10.30in',
              width: '2.00in',
              lineHeight: '0.16in',
            }}
            align="center"
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {dados.DESCR_COND_PAGTO}
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '0.50in',
              left: '11.37in',
              width: '0.37in',
              lineHeight: '0.21in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '10pt',
                fontFamily: 'Verdana',
                color: '#0000ff',
              }}
            >
              {trocaDePed(dados.PEDIDO, dados.NUMERO_PRE_PEDIDO).pedidoNumber}
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '10pt',
                fontFamily: 'Verdana',
                color: '#0000ff',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '0.25in',
              left: '11.37in',
              width: '0.89in',
              lineHeight: '0.17in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {alterarData(dados.EMITIDOEM)}
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '0.31in',
              left: '3.60in',
              width: '2.00in',
              lineHeight: '0.18in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '8pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              CLIENTE COMPRADOR:
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '8pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
            <div style={{ position: 'relative', left: '0.71in' }}>
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '8pt',
                  fontFamily: 'Verdana',
                  color: '#000000',
                }}
              >
                {dados.IDFILIALCOBRANCA}
              </span>
            </div>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '0.52in',
              left: '3.60in',
              width: '2.00in',
              lineHeight: '0.16in',
            }}
          >
            <div style={{ position: 'relative', left: '1.20in' }}>
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '8pt',
                  fontFamily: 'Verdana',
                  color: '#000000',
                }}
              >
                {dados.CLIENTE}
              </span>
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '8pt',
                  fontFamily: 'Verdana',
                  color: '#000000',
                }}
              >
                {' '}
              </span>
              <br />
            </div>
          </div>
          <div
            style={{
              position: 'absolute',
              top: '0.97in',
              left: '4.30in',
              width: '10.12in',
              lineHeight: '0.16in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {formatarCNPJ(dados.CNPJ)}
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            />
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '1.13in',
              left: '4.30in',
              width: '10.00in',
              lineHeight: '0.16in',
            }}
          >
            {/* Email Comprador */}
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                // fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {complementOrders.map((complement) => complement.EMAIL)}
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '2.18in',
              left: '4.30in',
              width: '10.00in',
              lineHeight: '0.16in',
            }}
          >
            {/* Email Entrega */}
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {complementOrders.map((complement) => complement.EMAIL)}
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '1.59in',
              left: '4.20in',
              width: '0.80in',
              lineHeight: '0.16in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {dados.IDFILIALENTREGA}
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '1.64in',
              left: '4.79in',
              width: '10.00in',
              lineHeight: '0.09in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {dados.CLIENTE_ENTR}
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '1.99in',
              left: '4.35in',
              width: '10.12in',
              lineHeight: '0.16in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {formatarCNPJ(dados.CNPJ_ENTR)}
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '2.36in',
              left: '0.46in',
              width: '1.14in',
              lineHeight: '0.22in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              Representante:
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '2.36in',
              left: '6.69in',
              width: '1.21in',
              lineHeight: '0.22in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              Transportadora:
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '2.36in',
              left: '11.00in',
              width: '0.46in',
              lineHeight: '0.22in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              Frete:
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '2.39in',
              left: '7.78in',
              width: '10.00in',
              lineHeight: '0.16in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '7pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {dados.TRANSPORTADORA}
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '7pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '2.39in',
              left: '11.40in',
              width: '0.29in',
              lineHeight: '0.16in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {dados.DESCR_TIPO_FRETE}
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '2.39in',
              left: '1.75in',
              width: '10.00in',
              lineHeight: '0.16in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {dados.REPRESENTANTE}
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '1.78in',
              left: '4.33in',
              width: '10.00in',
              lineHeight: '0.16in',
            }}
          >
            {/* Endereço de Entrega */}
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {complementOrders.map(
                (complement) =>
                  `${complement.ENDERECO}, ${complement.NUMERO_ENDERECO} - ${
                    complement.BAIRRO
                  } - ${complement.CIDADE} - ${
                    complement.SIGLA_ESTADO
                  } - ${formatCEP(complement.CEP)}`,
              )}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '0.75in',
              left: '4.33in',
              width: '10.00in',
              lineHeight: '0.16in',
            }}
          >
            {/* Endereço do Comprador */}
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {complementOrders.map(
                (complement) =>
                  `${complement.ENDERECO}, ${complement.NUMERO_ENDERECO} - ${
                    complement.BAIRRO
                  } - ${complement.CIDADE} - ${
                    complement.SIGLA_ESTADO
                  } - ${formatCEP(complement.CEP)}`,
              )}
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <img
            style={{
              position: 'absolute',
              top: '2.58in',
              left: '0.44in',
              width: '11.91in',
              height: '0.21in',
            }}
            src={Vi12}
          />
          <div
            style={{
              position: 'absolute',
              top: '2.61in',
              left: '0.35in',
              width: '10.00in',
              lineHeight: '0.15in',
            }}
          >
            <table width="100%">
              <thead>
                <tr>
                  <th
                    scope="col"
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 'bold',
                      fontSize: '7pt',
                      fontFamily: 'Verdana',
                      color: '#000000',
                    }}
                  >
                    Reduzido
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 'bold',
                      fontSize: '7pt',
                      fontFamily: 'Verdana',
                      color: '#000000',
                    }}
                  >
                    Ordem{' '}
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 'bold',
                      fontSize: '7pt',
                      fontFamily: 'Verdana',
                      color: '#000000',
                    }}
                  >
                    Cor
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 'bold',
                      fontSize: '7pt',
                      fontFamily: 'Verdana',
                      color: '#000000',
                    }}
                  >
                    Com.(%)
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 'bold',
                      fontSize: '7pt',
                      fontFamily: 'Verdana',
                      color: '#000000',
                    }}
                  >
                    Larg
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 'bold',
                      fontSize: '7pt',
                      fontFamily: 'Verdana',
                      color: '#000000',
                    }}
                  >
                    Acab.
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 'bold',
                      fontSize: '7pt',
                      fontFamily: 'Verdana',
                      color: '#000000',
                    }}
                  >
                    U.M
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 'bold',
                      fontSize: '7pt',
                      fontFamily: 'Verdana',
                      color: '#000000',
                    }}
                  >
                    Qtde
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 'bold',
                      fontSize: '7pt',
                      fontFamily: 'Verdana',
                      color: '#000000',
                    }}
                  >
                    Preço
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 'bold',
                      fontSize: '7pt',
                      fontFamily: 'Verdana',
                      color: '#000000',
                    }}
                  >
                    Preço Total
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 'bold',
                      fontSize: '7pt',
                      fontFamily: 'Verdana',
                      color: '#000000',
                    }}
                  >
                    Data Entrega
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 'bold',
                      fontSize: '7pt',
                      fontFamily: 'Verdana',
                      color: '#000000',
                    }}
                  >
                    Observação Item
                  </th>
                </tr>
              </thead>
              {itens.map((itens) => (
                <tbody key={itens.REDUZIDO}>
                  <tr align="center">
                    <td
                      style={{
                        fontStyle: 'normal',
                        fontSize: '7pt',
                        fontFamily: 'Verdana',
                        color: '#000000',
                      }}
                    >
                      {itens.REDUZIDO}
                    </td>
                    <td
                      style={{
                        fontStyle: 'normal',
                        fontSize: '7pt',
                        fontFamily: 'Verdana',
                        color: '#000000',
                      }}
                    >
                      {itens.DESCRICAO}{' '}
                    </td>
                    <td
                      style={{
                        fontStyle: 'normal',
                        fontSize: '7pt',
                        fontFamily: 'Verdana',
                        color: '#000000',
                      }}
                    />
                    <td
                      style={{
                        fontStyle: 'normal',
                        fontSize: '7pt',
                        fontFamily: 'Verdana',
                        color: '#000000',
                      }}
                    />
                    <td
                      style={{
                        fontStyle: 'normal',
                        fontSize: '7pt',
                        fontFamily: 'Verdana',
                        color: '#000000',
                      }}
                    >
                      {itens.LARGURA}
                    </td>
                    <td
                      style={{
                        fontStyle: 'normal',
                        fontSize: '7pt',
                        fontFamily: 'Verdana',
                        color: '#000000',
                      }}
                    />
                    <td
                      style={{
                        fontStyle: 'normal',
                        fontSize: '7pt',
                        fontFamily: 'Verdana',
                        color: '#000000',
                      }}
                    >
                      {itens.SIGLA_UM}
                    </td>
                    <td
                      style={{
                        fontStyle: 'normal',
                        fontSize: '7pt',
                        fontFamily: 'Verdana',
                        color: '#000000',
                      }}
                    >
                      {new Intl.NumberFormat({
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(itens.QUANTIDADE_DIGITACAO)}
                    </td>
                    <td
                      style={{
                        fontStyle: 'normal',
                        fontSize: '7pt',
                        fontFamily: 'Verdana',
                        color: '#000000',
                      }}
                    >
                      R${' '}
                      {new Intl.NumberFormat('pt-BR', {
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(itens.PRECOUNITARIO)}
                    </td>
                    <td
                      style={{
                        fontStyle: 'normal',
                        fontSize: '7pt',
                        fontFamily: 'Verdana',
                        color: '#000000',
                      }}
                      id="teste"
                    >
                      R${' '}
                      {new Intl.NumberFormat('pt-BR', {
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(
                        itens.PRECOUNITARIO * itens.QUANTIDADE_DIGITACAO,
                      )}
                    </td>
                    <td
                      style={{
                        fontStyle: 'normal',
                        fontSize: '7pt',
                        fontFamily: 'Verdana',
                        color: '#000000',
                      }}
                    />
                    <td
                      style={{
                        fontStyle: 'normal',
                        fontSize: '7pt',
                        fontFamily: 'Verdana',
                        color: '#000000',
                        maxWidth: '20px',
                      }}
                    >
                      {itens.OBS}
                    </td>
                  </tr>
                  <img
                    style={{
                      position: 'absolute',
                      left: '0.13in',
                      width: '11.91in',
                      height: '0.01in',
                    }}
                    src={Vi13}
                  />
                </tbody>
              ))}
            </table>

            <div
              style={{
                position: 'absolute',
                left: '5.4in',
                width: '0.50in',
                lineHeight: '0.17in',
              }}
            >
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: '8pt',
                  fontFamily: 'Verdana',
                  color: '#000000',
                }}
              >
                Total:
              </span>
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: '8pt',
                  fontFamily: 'Verdana',
                  color: '#000000',
                }}
              >
                {' '}
              </span>
            </div>
            <div
              style={{
                position: 'absolute',
                left: '6.12in',
                width: '0.43in',
                lineHeight: '0.17in',
              }}
            >
              {/* total */}
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: '8pt',
                  fontFamily: 'Verdana',
                  color: '#000000',
                }}
              >
                {valorTotal.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </span>
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: '8pt',
                  fontFamily: 'Verdana',
                  color: '#000000',
                }}
              >
                {' '}
              </span>
            </div>
            <div
              style={{
                position: 'absolute',
                left: '7.43in',
                width: '0.43in',
                lineHeight: '0.17in',
              }}
            >
              {/* total */}
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: '8pt',
                  fontFamily: 'Verdana',
                  color: '#000000',
                }}
              />
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: '8pt',
                  fontFamily: 'Verdana',
                  color: '#000000',
                }}
              >
                {' '}
              </span>
              <br />
            </div>
            <div
              style={{
                position: 'absolute',
                left: '0.10in',
                width: '11.91in',
                lineHeight: '0.17in',
              }}
            >
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: '8pt',
                  fontFamily: 'Verdana',
                  color: '#000000',
                }}
              >
                OBS.:
              </span>
              <br />
              <img
                style={{
                  position: 'absolute',
                  width: '11.91in',
                  height: '0.01in',
                }}
                src={Vi15}
              />
            </div>
            <br />
            <div
              style={{
                position: 'relative',
                marginTop: '5px',
                left: '0.20in',
                width: '5.06in',
                lineHeight: '0.21in',
              }}
            >
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '10pt',
                  fontFamily: 'Arial',
                  color: '#000000',
                }}
              >
                {veririfcarnull(dados.OBS)}
              </span>
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '10pt',
                  fontFamily: 'Arial',
                  color: '#000000',
                }}
              >
                {' '}
              </span>
              <br />
            </div>
            <br />
            <br />
            <br />
            <div
              style={{
                position: 'absolute',
                left: '0.10in',
                width: '0.84in',
                lineHeight: '0.16in',
              }}
            >
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '8pt',
                  fontFamily: 'Verdana',
                  color: '#000000',
                }}
              >
                Digitado Em:
              </span>
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '8pt',
                  fontFamily: 'Verdana',
                  color: '#000000',
                }}
              >
                {' '}
              </span>
              <br />
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '8pt',
                  fontFamily: 'Verdana',
                  color: '#000000',
                }}
              >
                Digitado por:
              </span>
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '8pt',
                  fontFamily: 'Verdana',
                  color: '#000000',
                }}
              >
                {' '}
              </span>
            </div>
            <div
              style={{
                position: 'absolute',
                left: '4.25in',
                width: '0.91in',
                lineHeight: '0.16in',
              }}
            >
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '8pt',
                  fontFamily: 'Verdana',
                  color: '#000000',
                }}
              >
                Aprovado por:
              </span>
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '8pt',
                  fontFamily: 'Verdana',
                  color: '#000000',
                }}
              >
                {' '}
              </span>
              <br />
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '8pt',
                  fontFamily: 'Verdana',
                  color: '#000000',
                }}
              >
                Aprovado Em:
              </span>
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '8pt',
                  fontFamily: 'Verdana',
                  color: '#000000',
                }}
              >
                {' '}
              </span>
            </div>
            <img
              style={{
                position: 'absolute',
                left: '8.76in',
                width: '3.28in',
                height: '1.26in',
              }}
              src={Vi14}
            />

            <div
              style={{
                position: 'absolute',
                left: '10.30in',
                width: '0.37in',
                lineHeight: '0.17in',
              }}
            >
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: '8pt',
                  fontFamily: 'Arial',
                  color: '#000000',
                }}
              >
                OBS.:
              </span>
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: '8pt',
                  fontFamily: 'Arial',
                  color: '#000000',
                }}
              >
                {' '}
              </span>
              <br />
            </div>
            <br />
            <div
              style={{
                position: 'absolute',
                left: '9.01in',
                width: '3.02in',
                lineHeight: '0.15in',
              }}
            >
              <div style={{ position: 'relative', left: '0.17in' }}>
                <span
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '8pt',
                    fontFamily: 'Verdana',
                    color: '#000000',
                  }}
                >
                  As mercadorias viajam por conta e risco do
                </span>
              </div>
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '8pt',
                  fontFamily: 'Verdana',
                  color: '#000000',
                }}
              >
                comprador. Não serão aceitas devoluções após 7
              </span>
              <div style={{ position: 'relative', left: '0.14in' }}>
                <span
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '8pt',
                    fontFamily: 'Verdana',
                    color: '#000000',
                  }}
                >
                  dias da data do recebimento da mercadoria.
                </span>
              </div>
              <div style={{ position: 'relative', left: '0.02in' }}>
                <span
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '8pt',
                    fontFamily: 'Verdana',
                    color: '#000000',
                  }}
                >
                  Consideramos a variação de +/- 20% (Vinte por
                </span>
                <span
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '8pt',
                    fontFamily: 'Verdana',
                    color: '#000000',
                  }}
                />
                <br />
              </div>
              <div style={{ position: 'relative', left: '0.06in' }}>
                <span
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '8pt',
                    fontFamily: 'Verdana',
                    color: '#000000',
                  }}
                >
                  cento) como pedido completo. Pedido sujeito a
                </span>
                <span
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '8pt',
                    fontFamily: 'Verdana',
                    color: '#000000',
                  }}
                />
              </div>
              <div style={{ position: 'relative', left: '1.10in' }}>
                <span
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '8pt',
                    fontFamily: 'Verdana',
                    color: '#000000',
                  }}
                >
                  confirmação.
                </span>
              </div>
            </div>
          </div>
          <img
            style={{
              position: 'absolute',
              top: '8.34in',
              left: '0.44in',
              width: '11.90in',
              height: '0.26in',
            }}
            src={Vi16}
          />
          <div
            style={{
              position: 'absolute',
              top: '8.38in',
              left: '5.54in',
              width: '1.48in',
              lineHeight: '0.16in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              Branyl Força de Vendas
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '8.38in',
              left: '11.87in',
              width: '0.43in',
              lineHeight: '0.16in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              1 de 1
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {' '}
            </span>
            <br />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '8.38in',
              left: '0.48in',
              width: '1.38in',
              lineHeight: '0.16in',
            }}
          >
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            >
              {date}
            </span>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '8pt',
                fontFamily: 'Verdana',
                color: '#000000',
              }}
            />
            <br />
          </div>
        </div>
      ))}
    </div>
  );
}

export default PDF;
