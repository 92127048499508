/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import { useEffect, useState, useContext } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import md5 from 'md5';
import {
  TabelaEstoque,
  Container,
  CircleColor,
  DivTabela,
  SearchIcon,
} from './styles';
import decimalToHexString from '../../components/search/DecimalToHex';
import { apiNode, apiTeste } from '../../services/api/axios.jsx';

function Estoque() {
  const [dados, setDados] = useState([]);
  const idRep = localStorage.getItem('idrep');
  const [resultado, setResultado] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    apiNode
      .get(`/estoqueRepresLinha?idRepresentante=${idRep}`)
      .then(async (response) => {
        setDados(response.data);
        setResultado(response.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const handlePesquisa = (e) => {
    const valorPesquisa = e.target.value.trim();
    const resultadoFiltrado = dados.filter((produto) =>
      produto.DESCRICAO.toUpperCase().includes(valorPesquisa.toUpperCase()),
    );

    setResultado(resultadoFiltrado);
  };

  return (
    <Container>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div />
        <Form style={{ width: '60%' }}>
          <InputGroup>
            <Form.Control
              type="text"
              onChange={(e) => {
                handlePesquisa(e);
              }}
            />
            <InputGroup.Text>
              <SearchIcon />
            </InputGroup.Text>
          </InputGroup>
        </Form>
      </div>
      <DivTabela>
        <TabelaEstoque striped>
          <thead>
            <tr className="theadFixed">
              <th>CODIGO</th>
              <th>DESCRICAO</th>
              <th>LARG.</th>
              <th>SALDO</th>
              {/* <th>CARTEIRA</th> */}
              <th>U.M</th>
              <th>COR</th>
            </tr>
          </thead>
          <tbody
            style={{
              display: resultado ? '' : 'none',
              verticalAlign: 'middle',
            }}
          >
            {resultado.map((produtos, index) => (
              <tr key={`${produtos.REDUZIDO}-${index}`}>
                <td>{produtos.REDUZIDO}</td>
                <td>{produtos.DESCRICAO}</td>
                <td>
                  {parseFloat(produtos.LARG).toFixed(2).replace('.', ',')}
                </td>
                <td>
                  {parseFloat(produtos.QTDE_ESTOQUE).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
                {/* <td>
                  {parseFloat(produtos.QTD_CARTEIRA).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td> */}
                <td>{produtos.SIGLA_UM}</td>
                <td style={{ width: '1%' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CircleColor
                      color={decimalToHexString(produtos.CODIGO_RGB)}
                    />{' '}
                    {produtos.DESCR_COR &&
                    typeof produtos.DESCR_COR === 'string'
                      ? produtos.DESCR_COR
                      : ''}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </TabelaEstoque>
        <div
          style={{
            display: loading ? '' : 'none',
            margin: '0 auto',
            width: '50%',
            textAlign: 'center',
            height: '60vh',
          }}
        >
          <ClipLoader />
        </div>
      </DivTabela>
      {/* <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '20px',
          paddingLeft: '10px',
          paddingRight: '10px',
        }}
      >
        <Button
          disabled={pagina === 1}
          onClick={() => {
            setPagina(pagina - 1);
            setDados([]);
          }}
        >
          <NextIcon />
        </Button>
        <span>{pagina}</span>
        <Button
          disabled={dados.length < 1}
          onClick={() => {
            setPagina(pagina + 1);
            setDados([]);
          }}
        >
          <PreviousIcon />
        </Button>
      </div> */}
    </Container>
  );
}

export default Estoque;
