import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import HashLoader from 'react-spinners/ClipLoader';
import { Button, Table } from 'react-bootstrap';
import { Container, DivTabela, CheckIcon } from './styles';
import api from '../../../services/api/axios';

function BuscarRepresentante() {
  const [repres, setRepres] = useState([]);
  const [textodigitado, setTextodigitado] = useState();
  const [pesquisa, setPesquisa] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    api
      .get(`Representante?pagina=0&busca=${pesquisa}`)
      .then(async (resultado) => {
        await setRepres(resultado.data);
        if (resultado.data.length > 0) {
          setLoading(false);
        }
      });
  }, [pesquisa]);

  function SetRepresStorage(prepres) {
    localStorage.setItem('repres', JSON.stringify(prepres));
  }

  return (
    <Container>
      <div className="mainsearch">
        <div className="mainsearch__search">
          <div>
            <input
              autoFocus
              onChange={(e) => setTextodigitado(e.target.value)}
              type="text"
              placeholder="Pesquisar por nome"
              aria-describedby="button-addon2"
            />
            <button
              onClick={() => {
                setPesquisa(textodigitado);
                setLoading(true);
                setRepres([]);
              }}
              className="clicksearch"
              type="button"
              id="button-addon2"
            >
              <i className="fa fa-search" />
            </button>
            <Link to="/Addpedido">
              <button type="button" className="clicksearch">
                <i className="fa fa-arrow-circle-left" />
              </button>
            </Link>
          </div>
        </div>
        <DivTabela>
          <Table>
            <thead>
              <tr>
                <th />
                <th>Código</th>
                <th>Nome</th>
              </tr>
            </thead>
            <tbody>
              {repres.map((representante) => (
                <tr key={representante.IDREPRESENTANTE}>
                  <td style={{ width: '10%' }}>
                    <Link to="/Addpedido">
                      <Button
                        onClick={() => {
                          SetRepresStorage([
                            representante.IDREPRESENTANTE,
                            representante.REPRESENTANTE,
                            representante.COMISSAONAAREA,
                          ]);
                        }}
                        type="button"
                        id="button-addon2"
                        style={{
                          width: '25px',
                          height: '25px',
                          position: 'relative',
                        }}
                      >
                        <CheckIcon
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                          }}
                        />
                      </Button>
                    </Link>
                  </td>
                  <td>{representante.IDREPRESENTANTE}</td>
                  <td>{representante.REPRESENTANTE}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </DivTabela>
        <div style={{ backgroundColor: '#FFFFFF', marginTop: '15px' }}>
          {loading ? (
            <HashLoader
              color="black"
              size={50}
              className="loading"
              speedMultiplier={1.2}
            />
          ) : null}
        </div>
      </div>
    </Container>
  );
}

export default BuscarRepresentante;
