import styled from 'styled-components';
import { CheckmarkOutline } from 'styled-icons/evaicons-outline';
import DatePicker from 'react-datepicker';

export const CheckIcon = styled(CheckmarkOutline)`
  width: 20px;
  height: 20px;
`;

export const BoxColor = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 10px;
  background-color: ${(props) => props.color};
  border: 0.1px solid #000000;
`;

export const DataInput = styled(DatePicker)`
  border: 1px solid var(--bs-gray-400);
  border-radius: 0.375rem;
  padding: 0.375rem 0.75rem;
  text-align: center;
`;
