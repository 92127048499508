import styled from 'styled-components';
import { CheckmarkOutline } from 'styled-icons/evaicons-outline';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  padding: 30px;
  .table {
    width: 100% !important;
  }
  .form-control {
    width: 70%;
    display: unset;
  }
`;

export const DivTabela = styled.div`
  width: 100%;
  border-radius: 20px;
  margin-top: 20px;
  overflow: auto;
  max-height: calc(100vh - 200px);
  &::-webkit-scrollbar {
    display: block;
    height: 10px;
    background-color: var(--tablestriped);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--outline);
    border-radius: 10px;
  }
`;

export const CheckIcon = styled(CheckmarkOutline)`
  width: 20px;
  height: 20px;
`;
