/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import {
  Book,
  Cart,
  PackageIcon,
  LineChart,
  HelpCircle,
  Exit,
} from 'styled-icons/boxicons-regular';
import { CloseOutline } from 'styled-icons/evaicons-outline';

export const Container = styled.div`
  color: red;
`;

const iconCss = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;

  @media only screen and (max-width: 1366px) {
    height: 20px;
    width: 20px;
  }

  @media only screen and (max-width: 968px) {
    width: 20px;
    height: 20px;
  }
`;

export const CartIcon = styled(Cart)`
  ${iconCss}
`;

export const SketchIcon = styled(Book)`
  ${iconCss}
`;

export const StorageIcon = styled(PackageIcon)`
  ${iconCss}
`;

export const ChartIcon = styled(LineChart)`
  ${iconCss}
`;

export const HelpIcon = styled(HelpCircle)`
  ${iconCss}
`;

export const LogoutIcon = styled(Exit)`
  ${iconCss}
`;

export const CloseIcon = styled(CloseOutline)`
  display: none;
  width: 20px;
  height: 20px;
  color: white;
  @media only screen and (max-width: 1000px) {
    display: flex;
  }
`;

export const AvatarIcon = styled.button`
  width: 50px;
  height: 50px;
  border: 0 none;
  background-color: orange;
  border-radius: 30px;
  color: #ffffff;
  font-weight: bolder;
  font-size: 25px;

  @media only screen and (max-width: 1366px) {
    height: 40px;
    width: 40px;
  }
`;

export const SideBarButton = styled.button`
  text-decoration: none;
  font-size: 32px;
  width: 100%;
  height: 120px;
  border: 0;
  background: #252625;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  color: ${(props) => props.color};

  @media only screen and (max-width: 1366px) {
    height: 60px;
  }

  @media only screen and (max-width: 968px) {
    height: 60px;
  }

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.color};
    color: white;
  }
`;
