/* eslint-disable react/button-has-type */
/* eslint-disable no-use-before-define */
import { useState } from 'react';
import './scrollReturn.css';
import { ButtonPosition, ArrowIcon } from './styles';

// eslint-disable-next-line react/prop-types
function ScrollReturn({ position }) {
  function returnPage() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  const [pageYPosition, setPageYPosition] = useState(0);

  window.addEventListener('scroll', getPageYAfterScroll);
  function getPageYAfterScroll() {
    setPageYPosition(window.scrollY);
  }

  return (
    pageYPosition > position && (
      // eslint-disable-next-line react/jsx-filename-extension
      <div src="./Order.css" className="buttonPostionDiv">
        <ButtonPosition onClick={() => returnPage()} variant="dark">
          <span style={{ fontWeight: 'bolder' }}>
            {' '}
            <ArrowIcon />
            VOLTAR
          </span>
        </ButtonPosition>
      </div>
    )
  );
}

export default ScrollReturn;
