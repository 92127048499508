import './grafico.css';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import HashLoader from 'react-spinners/HashLoader';
import api from '../../../services/api/axios';
import { Container } from './styles';
import SomaTotal from '../somaTotal.js';
import DateSet from '../date';

function VendasNome() {
  const [pedidos, setPedidos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nome, setNome] = useState('');

  const { id } = useParams();

  useEffect(() => {
    api
      .get(
        `VendaRepresPorLinha?nome_repres=${id}&ano_mes=${
          DateSet().currentYearMonth
        }`,
      )
      .then(async (response) => {
        await setPedidos(response.data);
        setLoading(false);
        setNome(id);
      })
      .catch((error) => {
        console.log(`erro ao receber lista de pedidos${error}`);
      });
  }, []);

  return (
    <Container>
      {pedidos.length > 0 ? (
        <div className="mainitempedidos" style={{ backgroundColor: '#FFFFFF' }}>
          <div className="itempedidomain1">
            <div className="orderitem__butons2">
              <div className="orderitem__butons1">
                <Link to="/graficoVendas">
                  <button
                    className="button__orderdata1"
                    type="button"
                    id="button-addon2"
                  >
                    {' '}
                    <i className="fa fa-arrow-circle-left" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{ margin: 'auto', display: 'block', textAlign: 'center' }}
            >
              <table
                style={{
                  backgroundColor: 'white',
                  width: '70%',
                  margin: 'auto',
                }}
                className="mainitempedidostable"
              >
                <thead>
                  <tr className="table__theadBackground">
                    <th scope="col" className="table__theadRepresentateVendas">
                      <span>
                        Representante: {nome} -{' '}
                        {SomaTotal(pedidos).somaTotalMes.toLocaleString(
                          'pt-br',
                          { style: 'currency', currency: 'BRL' },
                        )}
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="table__thead"
                      style={{
                        width: '20%',
                        marginLeft: '10px',
                        marginRight: '10px',
                      }}
                    >
                      Total Mês
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pedidos.map((pedido) => (
                    <tr>
                      <td
                        className="table__tbody"
                        style={{
                          height: '30px',
                          borderBottom: '1px solid black',
                          borderTop: '0 none',
                        }}
                      >
                        {pedido.LINHA}
                      </td>
                      <td
                        className="table__tbody"
                        style={{
                          borderBottom: '1px solid black',
                          borderTop: '0 none',
                        }}
                      >
                        {pedido.TOTAL_MES.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : null}
      <div style={{ margin: 'auto', display: 'block' }}>
        {loading ? (
          <HashLoader
            type="spin"
            color="black"
            size={50}
            className="loading"
            speedMultiplier={2}
          />
        ) : null}
      </div>
    </Container>
  );
}

export default VendasNome;
