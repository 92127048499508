/* eslint-disable no-console */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable camelcase */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Button, Table } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import HashLoader from 'react-spinners/ClipLoader';
import { Color, Container, DivTabela, PlusIcon, Tabela } from './styles';
import decimalToHexString from '../DecimalToHex';
import api from '../../../services/api/axios';
import './searchBar.css';
import { ModalNovoItem } from '../../modal';

function BuscaProdutos() {
  const id_rep = localStorage.getItem('idrep');
  const idcliente = JSON.parse(localStorage.getItem('clicomp'));
  const [loading, setLoading] = useState(false);
  const [produtos, setProdutos] = useState([]);
  const [textodigitado, setTextodigitado] = useState('');
  const [sucesso, setSucesso] = useState('N');
  const [mensagem, setMensagem] = useState(false);
  const [textoInformativo, setTextoinformativo] = useState('');
  const [valida, setValida] = useState(false);
  const [produtosComprados, setProdutosComprados] = useState([]);
  const [pesquisa, setPesquisa] = useState([]);
  const [inputSearch, setInputSearch] = useState('');
  const [filterSearch, setFilterSearch] = useState([]);
  const [pagina, setPagina] = useState(1);
  const [dados, setDados] = useState();

  localStorage.removeItem('valunit');
  localStorage.removeItem('qtde-it');
  localStorage.removeItem('observit');

  let idcli = '';
  const strprod = 'prods';
  let jaadicionado = false;
  if (idcliente) {
    idcli = idcliente[0];
  }

  useEffect(() => {
    api
      .get(`UltimosProdutos?pagina=1&id_cli=${idcli}`)
      .then(async (resultado) => {
        await setProdutosComprados(resultado.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log('erro ao receber lista de pedidos');
      });
  }, []);

  function ListarProdutosBuscados() {
    if (textodigitado.length > 1) {
      const pagina = 1;
      // setando pesquisa na API
      api
        .get(
          `Produtos?id_rep=${id_rep}&pagina=1&busca=${textodigitado}&id_cli=${idcli}`,
        )
        .then(async (resultado) => {
          await setProdutos(resultado.data);
          if (resultado.data.length > 0) {
            setLoading(false);
          }
          if (!resultado.data.length === false) {
            /* empty */
          } else {
            setValida(true);
          }
        });
      setTextoinformativo('');
    } else {
      setTextoinformativo('DIGITE 2 CARACTERES OU +');
      setProdutos([]);
      setLoading(false);
    }
  }

  function ListarProdutosBuscadosMais() {
    const count = pagina;
    setPagina(count + 1);
    setLoading(true);
    // setando pesquisa na API
    api
      .get(
        `Produtos?id_rep=${id_rep}&pagina=${
          count + 1
        }&busca=${textodigitado}&id_cli=${idcli}`,
      )
      .then(async (resultado) => {
        await setProdutos([...produtos, ...resultado.data]);
        if (resultado.data.length > 0) {
          setLoading(false);
        }
        if (!resultado.data.length === false) {
          /* empty */
        } else {
          setValida(true);
        }
      });
  }

  function SetProdutoStorage(produto) {
    const ListaProdAdd = JSON.parse(localStorage.getItem('jsonitens'));
    const redatual = produto[0];

    if (ListaProdAdd !== null) {
      ListaProdAdd.forEach((it) => {
        if (redatual === it.REDUZIDO) {
          jaadicionado = true;
        }
      });
    }
    if (!jaadicionado) {
      localStorage.setItem(strprod, JSON.stringify(produto));
      setSucesso('S');
    }
    setMensagem(jaadicionado);
  }

  useEffect(() => {
    if (inputSearch === '') {
      setFilterSearch([]);
    }
    setTextodigitado(inputSearch);
    if (textodigitado.length >= 2) {
      api
        .get(`Produtos?id_rep=${id_rep}&pagina=0&busca=${textodigitado}`)
        .then(async (resultado) => {
          await setPesquisa(resultado.data);
        });
    }
  }, [inputSearch]);

  const handlerFilter = (event) => {
    setInputSearch(event.target.value);

    const newFilter = pesquisa.filter((value) =>
      value.DESCRICAO.toLowerCase().includes(inputSearch.toLowerCase()),
    );

    setFilterSearch(newFilter);
  };

  function handleClickAutoComplete(value) {
    setInputSearch(value.DESCRICAO);
    setFilterSearch([]);
  }

  return (
    <Container>
      <div className="mainsearch">
        <div className="mainsearch__search">
          <div>
            <p className="textoInformativo">{textoInformativo}</p>
            <input
              autoFocus
              onChange={(e) => {
                handlerFilter(e);
              }}
              value={inputSearch}
              type="text"
              placeholder="Pesquisar descrição ou código do produto - Digite 2 Caracteres ou +"
              aria-describedby="button-addon2"
            />
            <button
              onClick={() => {
                ListarProdutosBuscados();
                setLoading(true);
                setProdutos([]);
                setPagina(1);
              }}
              className="clicksearch"
              type="button"
              id="button-addon2"
            >
              <i className="fa fa-search" />
            </button>
            <Link to="/Itensdopedido">
              <button className="clicksearch" type="button">
                {' '}
                <i className="fa fa-arrow-circle-left" />
              </button>
            </Link>
            {filterSearch.length !== 0 && (
              <div className="dataResult">
                {filterSearch.slice(0, 15).map((value) => (
                  <div
                    key={value.REDUZIDO}
                    className="dataItem"
                    onClick={() => handleClickAutoComplete(value)}
                  >
                    <p>{value.DESCRICAO}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <DivTabela>
          <Tabela striped responsive>
            <thead className="theadFixed">
              <tr>
                <th> : </th>
                <th>COD.</th>
                <th>DESCRICAO</th>
                <th>U.M</th>
                <th>LARG.</th>
                <th>COR</th>
              </tr>
            </thead>
            {produtos.length === 0 && textodigitado.length === 0 ? (
              <tbody style={{ verticalAlign: 'middle' }}>
                {produtosComprados.map((produto) => (
                  <tr
                    key={produto.REDUZIDO}
                    style={{ verticalAlign: 'middle' }}
                  >
                    <td>
                      {/* <Button
                        onClick={() => {
                          SetProdutoStorage([
                            produto.REDUZIDO,
                            produto.DESCRICAO,
                            produto.COD_COM,
                            produto.LARGURA,
                            produto.SIGLA_UM,
                            produto.DESCR_UM,
                            produto.ID_UM,
                            decimalToHexString(parseInt(produto.CODIGO_RGB)),
                            produto.COR,
                          ]);
                        }}
                        type="button"
                        variant="success"
                        style={{
                          width: '25px',
                          height: '25px',
                          position: 'relative',
                        }}
                      >
                        <CheckIcon
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                          }}
                        />
                      </Button> */}
                      {produto ? (
                        <ModalNovoItem variant="success" dados={produto} />
                      ) : null}
                    </td>
                    <td>{produto.REDUZIDO}</td>
                    <td>{produto.DESCRICAO}</td>
                    <td>{produto.SIGLA_UM}</td>
                    <td>{produto.LARGURA}</td>
                    <td>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Color
                          style={{
                            backgroundColor: decimalToHexString(
                              parseInt(produto.CODIGO_RGB),
                            ),
                          }}
                        />
                        {produto.COR}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : null}
            {/* produtos da pesquisa */}
            <tbody>
              {produtos.map((produto) => (
                <tr key={produto.REDUZIDO} style={{ verticalAlign: 'middle' }}>
                  <td>
                    {/* <Button
                      onClick={() => {
                        SetProdutoStorage([
                          produto.REDUZIDO,
                          produto.DESCRICAO,
                          produto.COD_COM,
                          produto.LARGURA,
                          produto.SIGLA_UM,
                          produto.DESCR_UM,
                          produto.ID_UM,
                          decimalToHexString(parseInt(produto.CODIGO_RGB)),
                          produto.COR,
                        ]);
                      }}
                      type="button"
                      variant="primary"
                      style={{
                        width: '25px',
                        height: '25px',
                        position: 'relative',
                      }}
                    >
                      <CheckIcon
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      />
                    </Button> */}

                    <ModalNovoItem
                      dados={{
                        REDUZIDO: produto.REDUZIDO,
                        DESCRICAO: produto.DESCRICAO,
                        COD_COM: produto.COD_COM,
                        LARGURA: produto.LARGURA,
                        SIGLA_UM: produto.SIGLA_UM,
                        DESCR_UM: produto.DESCR_UM,
                        ID_UM: produto.ID_UM,
                        CODIGO_RGB: decimalToHexString(
                          parseInt(produto.CODIGO_RGB),
                        ),
                        COR: produto.COR,
                      }}
                      onClick={() => setDados(produto)}
                    />
                  </td>
                  <td>{produto.REDUZIDO}</td>
                  <td>{produto.DESCRICAO}</td>
                  <td>{produto.SIGLA_UM}</td>
                  <td>{produto.LARGURA}</td>
                  <td>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Color
                        style={{
                          backgroundColor: decimalToHexString(
                            parseInt(produto.CODIGO_RGB),
                          ),
                        }}
                      />
                      {produto.COR}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Tabela>
        </DivTabela>
      </div>
      <div
        style={{
          backgroundColor: '#FFFFFF',
          marginTop: '15px',
          display: produtos.length === 0 ? 'flex' : 'none',
          justifyContent: 'center',
        }}
      >
        {loading ? (
          <HashLoader
            color="black"
            size={50}
            className="loading"
            speedMultiplier={1.2}
          />
        ) : null}
      </div>
      <div
        style={{
          display: produtos.length === 0 ? 'none' : '',
          width: '100%',
          textAlign: 'end',
        }}
      >
        <Button
          onClick={() => ListarProdutosBuscadosMais()}
          variant="dark"
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            width: '120px',
            height: '40px',
          }}
          disabled={loading}
        >
          {loading ? (
            <span
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                marginRight: '2px',
              }}
            >
              <HashLoader color="white" size={30} speedMultiplier={1.2} />
            </span>
          ) : (
            <>
              <span
                style={{
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  marginRight: '2px',
                }}
              >
                Carregar
              </span>
              <PlusIcon
                style={{ display: 'inline-block', verticalAlign: 'middle' }}
              >
                +
              </PlusIcon>
            </>
          )}
        </Button>
      </div>
      {sucesso === 'S' ? <Navigate to="/Novoitem" /> : null}
      {valida ? (
        <SweetAlert
          danger
          title="Alerta!"
          customButtons={
            <button
              src="./itempedido.css"
              className="buttonalert__confirm"
              onClick={() => {
                setValida(false);
                setLoading(false);
              }}
            >
              {' '}
              OK{' '}
            </button>
          }
        >
          Nenhum item encontrado
        </SweetAlert>
      ) : null}
      {mensagem ? (
        <SweetAlert
          danger
          title="Item ja adicionado!!"
          customButtons={
            <button
              src="./itempedido.css"
              className="buttonalert__confirm"
              onClick={() => {
                setMensagem(false);
                setLoading(false);
              }}
            >
              {' '}
              OK{' '}
            </button>
          }
        />
      ) : null}
    </Container>
  );
}

export default BuscaProdutos;
