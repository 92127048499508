import React from 'react';
import { Link } from 'react-router-dom';

function BuscaFormaPgto() {
  const formaspgto = [
    'Duplicata',
    'Dinheiro',
    'Cartão de Crédito',
    'Cartão BNDS',
    'Transferencia Bancaria',
  ];

  function SetFormapgto(frmpgto) {
    localStorage.setItem('frmpgto', JSON.stringify(frmpgto));
  }

  return (
    <div className="containerMain">
      <div className="mainsearch">
        <h3>
          Formas de Pagamento
          <Link to="/Addpedido">
            <button type="button" className="clicksearch">
              <i className="fa fa-arrow-circle-left" />
            </button>
          </Link>
        </h3>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr className="buscatipopedido">
                <th scope="col" className="col-acao" />
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {formaspgto.map((frmpgto) => {
                const i = formaspgto.indexOf(frmpgto);
                return (
                  <tr key={i} className="buscatipopedido">
                    <td>
                      <Link to="/Addpedido">
                        <button
                          onClick={() => {
                            SetFormapgto([i, frmpgto]);
                          }}
                          className="clicksearch"
                          type="button"
                          id="button-addon2"
                        >
                          <i className="fa fa-check" />
                        </button>
                      </Link>
                    </td>
                    <td>{frmpgto}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default BuscaFormaPgto;
