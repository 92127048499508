/* eslint-disable no-param-reassign */
/* eslint-disable no-bitwise */
function decimalToHexString(decimal) {
  // os valores do decimal entra de forma incorreta com o blue invertido com o red
  const red = decimal & 0xff;
  const green = (decimal >> 8) & 0xff;
  const blue = (decimal >> 16) & 0xff;

  function RGBToHex(r, g, b) {
    r = r.toString(16);
    g = g.toString(16);
    b = b.toString(16);

    if (r.length === 1) r = `0${r}`;
    if (g.length === 1) g = `0${g}`;
    if (b.length === 1) b = `0${b}`;

    return `#${r}${g}${b}`;
  }

  const resultado = RGBToHex(red, green, blue);

  return resultado;
}

export default decimalToHexString;
