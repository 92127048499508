import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { ArrowUpShort } from 'styled-icons/bootstrap';

export const ButtonPosition = styled(Button)`
  position: fixed;
  bottom: 20px;
  right: 68px;
  text-align: center;
  width: 112px;
  height: 42px;
  outline: none;
`;

export const ArrowIcon = styled(ArrowUpShort)`
  width: 25px;
  height: 25px;
`;
