function Status(number) {
  let text = '';
  let hex = '';
  if (
    number === 0 ||
    number === 1 ||
    number === 2 ||
    number === 4 ||
    number === 5
  ) {
    text = 'DIGITADO';
    hex = '#019efe';
  } else if (number === 6) {
    text = 'CANCELADO';
    hex = '#dc143c';
  } else if (
    number === 3 ||
    number === 7 ||
    number === 8 ||
    number === 9 ||
    number === 10 ||
    number === 11 ||
    number === 16
  ) {
    text = 'LIBERADO';
    hex = '#008000';
  } else if (number === 12 || number === 13 || number === 14) {
    text = 'CONCLUIDO';
    hex = '#0000ff';
  } else if (number === 15) {
    text = 'AG. LIBERAÇÃO';
    hex = '#ff7f50';
  }
  return { text, hex };
}

export default Status;
