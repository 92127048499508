function Descformpagt(value) {
  const dic = new Map();

  dic.set(1, 'DUPLICATA');
  dic.set(2, 'DINHEIRO');
  dic.set(3, 'CARTÃO DE CRÉDITO');
  dic.set(4, 'CARTÃO BNDS');
  dic.set(5, 'CARTA DE CRÉDITO');
  dic.set(6, 'TRANSFERENCIA BANCARIA');

  return dic.get(value);
}

export default Descformpagt;
