import styled from 'styled-components';
import { InputGroup, Form } from 'react-bootstrap';
import { SearchAlt2 } from 'styled-icons/boxicons-regular';

export const Search = styled(InputGroup)`
  border-radius: 14px;
  width: 50vw;
  @media (max-width: 500px) {
    width: auto;
  }
`;

export const SearchText = styled(InputGroup.Text)`
  margin-left: 1.75rem;
  background-color: ${(props) => props.bgColor};
  border-color: ${(props) => props.bdColor};
  @media (max-width: 700px) {
    margin-left: 15px;
  }
`;

export const FormControl = styled(Form.Control)`
  background-color: #262526;
  border-color: #858385;
  color: var(--white);
  width: 10px;

  :focus {
    box-shadow: 0 0 0 0;
    border-color: var(--white);
    outline: 0;
    background-color: #363436;
    color: var(--white);
  }
`;

export const SearchIcon = styled(SearchAlt2)`
  width: 25px;
  height: 25px;
  color: ${(props) => props.color};
`;
