import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Table } from 'react-bootstrap';
import HashLoader from 'react-spinners/ClipLoader';
import { CheckIcon, Container, DivTabela } from './styles';
import api from '../../../services/api/axios';

import './buscacliente.css';

function BuscarClienteEntr() {
  const idRep = localStorage.getItem('idrep');
  const [cliente, setCliente] = useState([]);
  const [textodigitado, setTextodigitado] = useState();
  const [pesquisa, setPesquisa] = useState();
  const [loading, setLoading] = useState(false);
  const nav = useNavigate();

  useEffect(() => {
    api
      .get(`Clientes?repres=${idRep}&pagina=1&nome=${pesquisa}`)
      .then(async (resultado) => {
        await setCliente(resultado.data);
        if (resultado.data.length > 0) {
          setLoading(false);
        }
      });
  }, [pesquisa]);

  function SetClientStorage(value) {
    localStorage.setItem('clieentr', JSON.stringify(value));
  }

  return (
    <Container>
      <div className="mainsearch">
        <div className="mainsearch__search">
          <div>
            <input
              autoFocus
              onChange={(e) => setTextodigitado(e.target.value)}
              type="text"
              placeholder="Pesquisar por nome"
              aria-describedby="button-addon2"
            />
            <button
              onClick={() => {
                setPesquisa(textodigitado);
                setLoading(true);
                setCliente([]);
              }}
              className="clicksearch"
              type="button"
              id="button-addon2"
            >
              <i className="fa fa-search" />
            </button>
            <Link to="/Addpedido">
              <button type="button" className="clicksearch">
                <i className="fa fa-arrow-circle-left" />
              </button>
            </Link>
          </div>
        </div>
        <DivTabela>
          <Table striped responsive style={{ textAlign: 'center' }}>
            <thead>
              <tr>
                <th />
                <th>Nome</th>
                <th>CNPJ</th>
                <th>Endereço</th>
                <th>Rep</th>
              </tr>
            </thead>
            <tbody>
              {cliente.map((cliente) => (
                <tr key={cliente.IDPESSOAFJ}>
                  <td style={{ width: '10%' }}>
                    <Button
                      onClick={() => {
                        SetClientStorage([
                          cliente.IDPESSOAFJ,
                          cliente.CNPJ,
                          cliente.CLIENTE,
                        ]);
                        nav('/Addpedido');
                      }}
                      type="button"
                      id="button-addon2"
                      style={{
                        width: '25px',
                        height: '25px',
                        position: 'relative',
                      }}
                    >
                      <CheckIcon
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      />
                    </Button>
                  </td>
                  <td>
                    {cliente.CLIENTE} ({cliente.IDPESSOAFJ})
                  </td>
                  <td>{cliente.CNPJ}</td>
                  <td>
                    {cliente.ENDERECO}, {cliente.NUMERO} - {cliente.BAIRRO} -{' '}
                    {cliente.CIDADE}/{cliente.UF}{' '}
                  </td>
                  <td>
                    {cliente.ID_REP} - {cliente.REPRESENTANTE}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </DivTabela>
        <div style={{ backgroundColor: '#FFFFFF', marginTop: '15px' }}>
          {loading ? (
            <HashLoader
              color="black"
              size={50}
              className="loading"
              speedMultiplier={1.2}
            />
          ) : null}
        </div>
      </div>
    </Container>
  );
}

export default BuscarClienteEntr;
