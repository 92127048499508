import styled from 'styled-components';
import { Table } from 'react-bootstrap';
import { SearchAlt2 } from 'styled-icons/boxicons-regular';
import { CheckmarkOutline } from 'styled-icons/evaicons-outline';
import { ArrowLeftCircle, CheckCircleFill } from 'styled-icons/bootstrap';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  padding: 30px;
  .table {
    width: 100% !important;
  }
  .form-control {
    width: 70%;
    display: unset;
  }
`;

export const Color = styled.button`
  width: 25px;
  height: 25px;
  border: 0.01px solid black;
  margin: auto;
  border-radius: 8px;
`;

export const DivTabela = styled.div`
  width: 100%;
  border-radius: 20px;
  margin-top: 20px;
  overflow: auto;
  max-height: calc(100vh - 300px);
  &::-webkit-scrollbar {
    display: block;
    height: 10px;
    background-color: var(--tablestriped);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--outline);
    border-radius: 10px;
  }
`;

export const Tabela = styled(Table)`
  .theadFixed {
    position: sticky;
    background-color: var(--white);
    border-radius: 20px;
    top: 0;
    z-index: 2;
  }
`;

export const PlusIcon = styled.span`
  font-size: 20px;
  font-weight: bolder;
`;

export const CheckIcon = styled(CheckmarkOutline)`
  width: 20px;
  height: 20px;
`;

export const ButtonBar = styled.button`
  border: 0 none;
  background-color: transparent;
  font-size: 20px;
  margin-left: 20px;
`;

export const SearchIcon = styled(SearchAlt2)`
  width: 30px;
  height: 30px;
`;

export const LeftIcon = styled(ArrowLeftCircle)`
  width: 25px;
  height: 25px;
`;

export const CheckFillIcon = styled(CheckCircleFill)`
  width: 20px;
  height: 20px;
  color: var(--success);
`;
