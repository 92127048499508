import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HashLoader from 'react-spinners/ClipLoader';
import { Button, Table } from 'react-bootstrap';
import { CheckIcon, Container } from './styles';
import api from '../../../services/api/axios';

function BuscarCondPgto() {
  const [condpgto, setCondpgto] = useState([]);
  const [textodigitado, setTextodigitado] = useState();
  const [pesquisa, setPesquisa] = useState();
  const [loading, setLoading] = useState(false);

  const nav = useNavigate();

  useEffect(() => {
    api
      .get(`CondicaoPagto?&pagina=0&busca=${pesquisa}`)
      .then(async (resultado) => {
        await setCondpgto(resultado.data);
        if (resultado.data.length > 0) {
          setLoading(false);
        }
      });
  }, [pesquisa]);

  function SetCliCompStorage(condpgto) {
    localStorage.setItem('condpgto', JSON.stringify(condpgto));
  }

  return (
    <Container>
      <div className="mainsearch">
        <div className="mainsearch__search">
          <div>
            <input
              autoFocus
              onChange={(e) => setTextodigitado(e.target.value)}
              type="text"
              className="form-control"
              placeholder="Pesquisar por descrição"
              aria-describedby="button-addon2"
            />
            <button
              onClick={() => {
                setPesquisa(textodigitado);
                setLoading(true);
                setCondpgto([]);
              }}
              className="clicksearch"
              type="button"
              id="button-addon2"
            >
              <i className="fa fa-search" />
            </button>
            <Link to="/Addpedido">
              <button type="button" className="clicksearch">
                <i className="fa fa-arrow-circle-left" />
              </button>
            </Link>
          </div>
        </div>
        <div>
          <Table striped responsive style={{ textAling: 'center' }}>
            <thead>
              <tr>
                <th />
                <th>Cód. </th>
                <th>Condição Pgto</th>
                <th>Parcelas</th>
              </tr>
            </thead>
            <tbody>
              {condpgto.map((condicao) => (
                <tr key={condicao.CONDICAO}>
                  <td>
                    <Button
                      onClick={() => {
                        SetCliCompStorage([
                          condicao.CONDICAO,
                          condicao.DESCRICAO,
                          condicao.PARCELAS,
                        ]);
                        nav('/Addpedido');
                      }}
                      type="button"
                      style={{
                        width: '25px',
                        height: '25px',
                        position: 'relative',
                      }}
                    >
                      <CheckIcon
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      />
                    </Button>
                  </td>
                  <td>{condicao.CONDICAO}</td>
                  <td>{condicao.DESCRICAO}</td>
                  <td>{condicao.PARCELAS} </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div style={{ backgroundColor: '#FFFFFF', marginTop: '15px' }}>
          {loading ? (
            <HashLoader
              color="black"
              size={50}
              className="loading"
              speedMultiplier={1.2}
            />
          ) : null}
        </div>
      </div>
    </Container>
  );
}

export default BuscarCondPgto;
