import './Order.css';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import { toast } from 'react-toastify';
import { Table } from 'react-bootstrap';
import LimparDados from './LimparDados';
import 'react-toastify/dist/ReactToastify.css';
import '@szhsin/react-menu/dist/core.css';

function Rascunho() {
  const [ListaPedidos, setListaPedidos] = useState([]);

  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    setListaPedidos(JSON.parse(localStorage.getItem('listaPedidos')));
  }, [refreshKey]);

  function atualizador() {
    setRefreshKey((oldKey) => oldKey + 1);
  }

  let cont = 1;
  const navigate = useNavigate();

  function CarregarPedido(posicao) {
    LimparDados();
    localStorage.removeItem('datarascunho');

    const map = new Map(Object.entries(ListaPedidos));
    localStorage.setItem(
      'clicomp',
      JSON.stringify(map.get(posicao.toString()).CLIENTECOMP),
    );
    localStorage.setItem(
      'clieentr',
      JSON.stringify(map.get(posicao.toString()).CLIENTEENTR),
    );
    localStorage.setItem(
      'cliefat',
      JSON.stringify(map.get(posicao.toString()).CLIENTEFAT),
    );
    localStorage.setItem(
      'repres',
      JSON.stringify(map.get(posicao.toString()).REPRESENTANTE),
    );
    localStorage.setItem(
      'tpped',
      JSON.stringify(map.get(posicao.toString()).TPPED),
    );
    localStorage.setItem(
      'frmpgto',
      JSON.stringify(
        map.get(posicao.toString()).FORMAPGTO === 0
          ? 1
          : map.get(posicao.toString()).FORMAPGTO,
      ),
    );
    localStorage.setItem(
      'condpgto',
      JSON.stringify(map.get(posicao.toString()).CONDPGTO),
    );
    localStorage.setItem(
      'transp',
      JSON.stringify(map.get(posicao.toString()).TRANSP),
    );
    localStorage.setItem(
      'tpfrete',
      JSON.stringify(map.get(posicao.toString()).TIPOFRETE),
    );
    localStorage.setItem(
      'parcial',
      JSON.stringify(map.get(posicao.toString()).PARCIAL),
    );
    localStorage.setItem(
      'pedrepres',
      map.get(posicao.toString()).PEDREPRESENTANTE === null
        ? ''
        : map.get(posicao.toString()).PEDREPRESENTANTE,
    );
    localStorage.setItem(
      'pedcliente',
      map.get(posicao.toString()).PEDCLIENTE === null
        ? ''
        : map.get(posicao.toString()).PEDCLIENTE,
    );
    localStorage.setItem(
      'observ',
      map.get(posicao.toString()).OBS === null
        ? ''
        : map.get(posicao.toString()).OBS,
    );
    localStorage.setItem(
      'parcial',
      map.get(posicao.toString()).PARCIAL === null
        ? 0
        : map.get(posicao.toString()).PARCIAL,
    );
    localStorage.setItem(
      'jsonitens',
      JSON.stringify(
        map.get(posicao.toString()).ITENS === null
          ? []
          : map.get(posicao.toString()).ITENS,
      ),
    );
  }

  function GerarPDF(posicao) {
    CarregarPedido(posicao);
    const map = new Map(Object.entries(ListaPedidos));
    localStorage.setItem(
      'datarascunho',
      JSON.stringify(map.get(posicao.toString()).DATARASCUNHO),
    );
  }

  function deletarTarefa(posicao) {
    ListaPedidos.splice(posicao, 1);
    atualizador();
    SalvarDados();
  }

  function SalvarDados() {
    localStorage.setItem('listaPedidos', JSON.stringify(ListaPedidos));
  }

  const customId = 'custom-id-rascunhodelete';

  const notify = () => {
    toast.error(`Rascunho deletado`, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customId,
    });
  };

  return (
    <div className="containerMain">
      <div style={{ padding: '20px', marginTop: '10px' }}>
        <Table style={{ textAlign: 'center', verticalAlign: 'middle' }} striped>
          <thead>
            <tr>
              <th>Opções</th>
              <th>Posição</th>
              <th>Cliente</th>
              <th>Ped. Cliente</th>
              <th>Data Rascunho</th>
              <th>Valor Total</th>
            </tr>
          </thead>
          <tbody>
            {ListaPedidos
              ? ListaPedidos.map((pedidos) => (
                  <tr key={pedidos.DATARASCUNHO}>
                    <td>
                      <Menu
                        menuButton={
                          <MenuButton className="config">
                            <i className="fa fa-cog" />
                          </MenuButton>
                        }
                        display="arrow"
                        align="start"
                        direction="right"
                        viewScroll="auto"
                        position="anchor"
                      >
                        <MenuItem style={{ width: '200px', margin: '10px' }}>
                          <button
                            className="buttontable"
                            type="button"
                            onClick={() => {
                              CarregarPedido(ListaPedidos.indexOf(pedidos));
                              navigate('/Addpedido');
                            }}
                          >
                            <i className="fa fa-eye" /> Carregar Rascunho
                          </button>
                        </MenuItem>
                        <MenuItem style={{ width: '200px', margin: '10px' }}>
                          <button
                            className="buttontable"
                            type="button"
                            onClick={() => {
                              GerarPDF(ListaPedidos.indexOf(pedidos));
                              navigate('/pdfrascunho');
                            }}
                          >
                            <i className="fa fa-file-pdf-o" /> Gerar PDF
                          </button>
                        </MenuItem>
                        <MenuItem style={{ width: '200px', margin: '10px' }}>
                          <button
                            className="buttontable"
                            type="button"
                            onClick={() => {
                              deletarTarefa(ListaPedidos.indexOf(pedidos));
                              notify();
                            }}
                          >
                            <i className="fa fa-trash-o" /> Excluir Rascunho
                          </button>
                        </MenuItem>
                      </Menu>
                    </td>
                    <td>{cont++}</td>
                    <td>
                      {' '}
                      <strong> {pedidos.CLIENTECOMP[2]} </strong>{' '}
                    </td>
                    <td>
                      <strong>{pedidos.PEDCLIENTE}</strong>
                    </td>
                    <td>{pedidos.DATARASCUNHO}</td>
                    <td>
                      {' '}
                      R${' '}
                      {new Intl.NumberFormat('pt-BR', {
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(pedidos.VALORTOTAL)}
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default Rascunho;
