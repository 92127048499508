import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import api from '../../services/api/axios';

import './buscacliente.css';

function BuscarTipoPedido() {
  const [tpped, setTpped] = useState([]);

  useEffect(() => {
    api.get('TiposPedido').then(async (resultado) => {
      await setTpped(resultado.data);
    });
  }, []);

  function SetRepresStorage(tipoped) {
    localStorage.setItem('tpped', JSON.stringify(tipoped));
  }

  return (
    <div className="containerMain">
      <div className="mainsearch">
        <h3>
          Tipo Pedido
          <Link to="/Addpedido">
            <button type="button" className="clicksearch">
              <i className="fa fa-arrow-circle-left" />
            </button>
          </Link>
        </h3>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr className="buscatipopedido">
                <th scope="col" />
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {tpped.map((tpped) => (
                <tr key={tpped.TIPOPEDIDO} className="buscatipopedido">
                  <td>
                    <Link to="/Addpedido">
                      <button
                        onClick={() => {
                          SetRepresStorage([
                            tpped.TIPOPEDIDO,
                            tpped.DESCR_TIPO_PEDIDO,
                          ]);
                        }}
                        className="clicksearch"
                        type="button"
                        id="button-addon2"
                      >
                        <i className="fa fa-check" />
                      </button>
                    </Link>
                  </td>
                  <td>{tpped.DESCR_TIPO_PEDIDO}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default BuscarTipoPedido;
