import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { CartAddIcon } from './styles';

export default function AddButton() {
  const nav = useNavigate();

  return (
    <Button
      variant="success"
      style={{
        width: '50px',
        height: '40px',
        position: 'relative',
      }}
      onClick={() => {
        nav('/Addpedido');
      }}
    >
      <CartAddIcon
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    </Button>
  );
}
