/* eslint-disable default-case */
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import 'moment/locale/pt-br'; // importa a localidade para o português do Brasil
import { NavBarTop, MenuIcon, RightArrowIcon, BackTime } from './styles.js';
import SidebarStatus from '../../contexts/sidebarContext.js';
import SearchBar from '../SearchBar/index.jsx';
import RefreshButton from '../RefreshButton/index.jsx';
import AddButton from '../OrderButton/index.jsx';
import SalvarRascunho from '../order/SalvarRascunho.jsx';
import LimparDados from '../order/LimparDados.js';

function Navbar() {
  const [sidebarStatus, setSidebarStatus] = useContext(SidebarStatus);
  const location = useLocation();
  const nav = useNavigate();
  const date = new Date(); // aqui você deve usar a data que deseja formatar
  const abbreviatedMonth =
    location.pathname === '/graficomesanterior' ||
    location.pathname === '/graficovendasanterior'
      ? moment(date).format('MMM')
      : moment(date).subtract(1, 'month').format('MMM'); // obtém a abreviação do mês

  const handleNavigate = (endpoint) => {
    switch (endpoint) {
      case '/graficoVendas':
        nav('/graficos');
        break;
      case '/graficos':
        nav('/graficoVendas');
        break;
      case '/graficomesanterior':
        nav('/graficovendasanterior');
        break;
      default:
        nav('/graficomesanterior');
        break;
    }
  };

  return (
    <NavBarTop>
      <MenuIcon
        onClick={() => {
          setSidebarStatus((state) => !state);
        }}
      />
      <span
        style={{
          display: location.pathname !== '/Pedidos' ? 'none' : '',
        }}
      >
        <SearchBar />
      </span>
      <div style={{ display: location.pathname !== '/Pedidos' ? 'none' : '' }}>
        <RefreshButton />
        <AddButton />
      </div>
      <div
        style={{
          width: '100%',
          justifyContent: 'space-between',
          padding: '08px',
          display:
            location.pathname !== '/graficos' &&
            location.pathname !== '/graficoVendas' &&
            location.pathname !== '/graficomesanterior' &&
            location.pathname !== '/graficovendasanterior'
              ? 'none'
              : 'flex',
          flexDirection:
            location.pathname !== '/graficos' &&
            location.pathname !== '/graficomesanterior'
              ? 'row-reverse'
              : 'row',
        }}
      >
        <Button
          variant="outline-light"
          style={{ width: '120px', height: '40px' }}
          onClick={() => {
            nav(
              location.pathname === '/graficomesanterior'
                ? '/graficos'
                : '/graficomesanterior',
            );
          }}
        >
          <strong
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              marginRight: '2px',
            }}
          >
            {abbreviatedMonth.toLocaleUpperCase()}
          </strong>
          <BackTime
            size="20"
            style={{ display: 'inline-block', verticalAlign: 'middle' }}
          />
        </Button>
        <Button
          variant="outline-light"
          style={{ width: '120px', height: '40px' }}
          onClick={() => {
            handleNavigate(location.pathname);
          }}
        >
          <strong>
            <RightArrowIcon
              style={{
                transform:
                  location.pathname !== '/graficos' &&
                  location.pathname !== '/graficomesanterior'
                    ? 'scaleX(-1) scaleY(-1)'
                    : 'none',
              }}
            />
          </strong>
        </Button>
      </div>
      <div
        style={{
          width: '100%',
          justifyContent: 'end',
          padding: '08px',
          display: location.pathname !== '/Addpedido' ? 'none' : 'flex',
        }}
      >
        <Button
          variant="outline-light"
          style={{ fontWeight: '600' }}
          onClick={() => {
            SalvarRascunho();
            nav('/rascunho');
            setTimeout(() => {
              LimparDados();
            }, 1000);
          }}
        >
          Rascunho
        </Button>
      </div>
    </NavBarTop>
  );
}

export default Navbar;
