/* eslint-disable no-restricted-globals */
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ptBR from 'date-fns/locale/pt-BR';
import moment from 'moment/moment';
import { BoxColor, CheckIcon, DataInput } from './styles';
import 'react-datepicker/dist/react-datepicker.css';
import { apiNode } from '../../services/api/axios';

export function ModalNovoItem({ variant, dados }) {
  const [QTDE, setQTDE] = useState();
  const [PRECO, setPRECO] = useState();
  const [OBS, setOBS] = useState('');

  const [show, setShow] = useState(false);

  const mndate = new Date();
  mndate.setDate(mndate.getDate() + 15);

  const [startDate, setStartDate] = useState(mndate);
  const [date, setDate] = useState(new Date());

  async function getServerDate() {
    await apiNode.get('/date').then((response) => {
      const data = response.data;
      const serverDate = new Date(data.data);
      serverDate.setDate(serverDate.getDate() + 15);

      setStartDate(serverDate);
      setDate(serverDate);
    });
  }

  useEffect(() => {
    getServerDate();
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // formatação do produto
  function FormataProd(pCodComercial) {
    const tipo = pCodComercial.substr(0, 2);
    const artigo = pCodComercial.substr(2, 6);
    const rev = pCodComercial.substr(8, 2);
    const cor = pCodComercial.substr(13, 5);
    const des = pCodComercial.substr(18, 4);
    const vari = pCodComercial.substr(22, 1);

    return `${tipo}.${artigo}.${rev}.${cor}.${des}.${vari}`;
  }

  // pegar os itens salvos no localstorage
  const LocalItensDoPedido = JSON.parse(localStorage.getItem('jsonitens'));

  const ItensDoPedido =
    localStorage.getItem('jsonitens') !== null ? LocalItensDoPedido : [];

  const nave = useNavigate();

  const [prodSelect, setProdSelect] = useState({
    reduz: dados.REDUZIDO,
    descricao: dados.DESCRICAO,
    larg: dados.LARGURA,
    codcom: dados.COD_COM,
    corHEX: dados.CODIGO_RGB,
    cor: dados.COR,
    idumpadrao: dados.ID_UM,
    um: '',
    descrum: '',
    idum: dados.ID_UM,
    entregar: false,
  });

  // função de adicionar item ao LocalStorage
  function AdicionarItem() {
    // validar qtdes
    if (QTDE && PRECO) {
      // criar um item do pedido
      const itemselecionado = {
        REDUZIDO: prodSelect.reduz,
        DESCRICAO: prodSelect.descricao,
        COD_COM: prodSelect.codcom,
        SIGLA_UM: prodSelect.idum === 3 ? 'M' : 'KG',
        DESCR_UM: prodSelect.idum === 3 ? 'Metro' : 'Quilograma',
        LARGURA: prodSelect.larg,
        ID_UM_PADRAO: prodSelect.idumpadrao,
        QTDE,
        PRECO,
        OBS,
        ID_UM: prodSelect.idum ? prodSelect.idum : prodSelect.idumpadrao,
        EXPEDIREM: parseInt(moment(startDate).format('YYYYMMDD')),
        NAO_ENTREGAR_ANTES: prodSelect.entregar ? 1 : 0,
      };

      // adicionar um item a lista de iten do pedido
      ItensDoPedido.push(itemselecionado);
      localStorage.setItem('jsonitens', JSON.stringify(ItensDoPedido));

      // redirecionar para lista dos pedidos
      nave('/Itensdopedido');

      handleClose();
    }
  }

  return (
    <>
      <Button
        onClick={() => {
          handleShow((state) => !state);
        }}
        type="button"
        variant={variant}
        style={{
          width: '25px',
          height: '25px',
          position: 'relative',
        }}
      >
        <CheckIcon
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </Button>
      {dados ? (
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Novo Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <BoxColor color={dados.CODIGO_RGB} />
              <div
                style={{
                  marginLeft: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <span style={{ fontWeight: '600' }}>{dados.DESCRICAO}</span>
                <span>Largura: {dados.LARGURA}</span>
                <span>
                  {dados.REDUZIDO} / {FormataProd(dados.COD_COM)}
                </span>
                <span
                  style={{
                    fontWeight: '600',
                    color: dados.CODIGO_RGB,
                    WebkitTextStroke: '0.4px black',
                  }}
                >
                  {dados.COR}
                </span>
              </div>
            </div>
            <Form style={{ marginTop: '10px' }}>
              <Row>
                <Form.Group as={Col}>
                  <Form.Label>Quantidade:</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      onBlur={(e) => {
                        const valor = e.target.value;
                        const valorSemVirgula = valor.replace(',', '.');
                        const valorFormatado = parseFloat(valorSemVirgula)
                          .toFixed(2)
                          .replace('.', ',');
                        e.target.value = valorFormatado;
                        setQTDE(valorSemVirgula);
                      }}
                      style={{ borderColor: QTDE ? 'green' : 'red' }}
                    />
                    <Form.Select
                      style={{
                        textAlign: 'center',
                        borderColor: QTDE ? 'green' : 'red',
                      }}
                      onChange={(event) => {
                        const selectedOption =
                          event.target.options[event.target.selectedIndex];
                        const selectedOptionText = selectedOption.text;
                        console.log(selectedOptionText);
                        setProdSelect({
                          ...prodSelect,
                          idum: parseInt(event.target.value),
                        });
                      }}
                      value={prodSelect.idum}
                    >
                      <option value="1"> KG </option>
                      <option value="3"> M </option>
                    </Form.Select>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Valor Unitario :</Form.Label>
                  <InputGroup>
                    <InputGroup.Text
                      style={{
                        borderColor: PRECO ? 'green' : 'red',
                      }}
                    >
                      R$
                    </InputGroup.Text>
                    <Form.Control
                      style={{
                        borderColor: PRECO ? 'green' : 'red',
                      }}
                      type="text"
                      onBlur={(e) => {
                        const valor = e.target.value;
                        const valorSemVirgula = valor.replace(',', '.');
                        const valorFormatado = parseFloat(valorSemVirgula)
                          .toFixed(2)
                          .replace('.', ',');
                        e.target.value = valorFormatado;
                        setPRECO(valorSemVirgula);
                      }}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Total do Item:</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    style={{
                      borderColor: QTDE && PRECO ? 'green' : 'red',
                    }}
                    value={
                      QTDE && PRECO
                        ? (QTDE * PRECO).toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })
                        : 'R$ 0,00'
                    }
                  />
                </Form.Group>
              </Row>
              <Form.Group style={{ marginTop: '10px' }}>
                <Form.Label>Entregar até:</Form.Label>{' '}
                <DataInput
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="dd/MM/yyyy"
                  locale={ptBR}
                  minDate={date}
                  className="teste"
                />
                <Form.Check
                  type="checkbox"
                  label="Não entregar antes da data solicitada"
                  onChange={(e) =>
                    setProdSelect({ ...prodSelect, entregar: e.target.checked })
                  }
                  style={{ marginTop: '10px' }}
                />
              </Form.Group>
              <Form.Group style={{ marginTop: '10px' }}>
                <Form.Label>Observação:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  onChange={(e) => {
                    setOBS(e.target.value);
                  }}
                  maxLength={100}
                />
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'end',
                  }}
                >
                  <span
                    style={{
                      fontSize: '12px',
                      fontWeight: 'bold',
                      color: OBS.length === 100 ? 'red' : 'black',
                    }}
                  >
                    {OBS ? OBS.length : '0'}
                    /100
                  </span>
                </div>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Button variant="danger" onClick={handleClose}>
              Fechar
            </Button>
            <Button
              variant="success"
              onClick={() => {
                AdicionarItem();
              }}
            >
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
}
