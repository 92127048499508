/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable radix */
/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import './grafico.css';
import { Chart } from 'react-google-charts';
import { useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import ClipLoader from 'react-spinners/ClipLoader';
import moment from 'moment/moment';
import { Table } from 'react-bootstrap';
import api from '../../../services/api/axios';
import DateSet from '../date';
import SomaTotal from '../somaTotal';
import ScrollReturn from '../../buttons/scrollReturn';
import { Container } from './styles.js';
import Username from '../../../contexts/User';
import { Porcentagem } from './Porcentagem';

function Grafico() {
  const [pedidos, setPedidos] = useState([]);
  const [gerentes, setGerentes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [carregar, setCarregar] = useState(false);
  const [chartData, setChartData] = useState([]);

  const idGerente = localStorage.getItem('id_gerente');
  const tpuser = JSON.parse(localStorage.getItem('tp_user'));

  const [exce, setExce] = useState([]); // Jose Gomes (GOMINHO)
  const [exceAnt, setExceAnt] = useState([]); // Jose Gomes (GOMINHO)
  const [exce2, setExce2] = useState([]); // Filho do Trabal (JOBSON)
  const [exce2Ant, setExce2Ant] = useState([]); // Filho do Trabal (JOBSON)

  const username = useContext(Username);

  const d = new Date();
  const data = moment(d, 'YYYY-M-D').format('YYYYMM');
  const dataAnt = moment(d, 'YYYY-M-D').subtract(1, 'year').format('YYYYMM');

  const loadData = (data) => {
    // mapeamento e estruturação para os dados no grafico
    const result = _.map(data, (value) => [
      value.NOME_REP.split(' ').slice(0, 1).join(' '),
      value.TOTAL_MES,
      value.MES_ANO_ANT,
    ]);

    return [
      [
        'Representante',
        `${DateSet().currentMes}/${DateSet().currentYear}`,
        `${DateSet().currentMes}/${DateSet().currentYearAnterior}`,
      ],
      ...result,
    ];
  };

  // opções do graficos
  const options = {
    chart: {
      title: 'Comparação de Faturamento.',
    },
    bars: 'horizontal',
    axes: {
      y: {
        0: { side: 'left' },
      },
    },
  };

  const [refreshKey, setRefreshKey] = useState(0);

  function atualizador() {
    setCarregar(false);
    setRefreshKey((oldKey) => oldKey + 1);
  }

  useEffect(() => {
    api
      .get(`PosicaoGerente?gerente=${idGerente}`)
      .then(async (response) => {
        const { data } = response;

        setPedidos(data);

        if (data) {
          setChartData(loadData(data));
        }

        if (response.data) {
          await setPedidos(response.data);
        } else {
          console.error('response.data é undefined');
        }
        setLoading(false);
        setCarregar(false);
      })
      .catch((error) => {
        console.error(`erro ao receber lista de pedidos${error}`);
      });
    api
      .get('/Gerentes')
      .then(async (response) => {
        await setGerentes(response.data);
      })
      .catch((error) => {
        console.error(`erro ao receber lista de pedidos${error}`);
      });

    if (Number(idGerente) === 5) {
      api
        .get(
          `/FatRepresPorLinha?nome_repres=JOSE%20GOMES%20REPR&ano_mes=${data}`,
        )
        .then(async (response) => {
          setExce(loadDataExce(response.data));
        })
        .catch((error) => {
          console.error(`erro ao receber lista de pedidos${error}`);
        });

      api
        .get(
          `/FatRepresPorLinha?nome_repres=JOSE%20GOMES%20REPR&ano_mes=${dataAnt}`,
        )
        .then(async (response) => {
          await setExceAnt(loadDataExce(response.data));
        })
        .catch((error) => {
          console.error(`erro ao receber lista de pedidos${error}`);
        });

      api
        .get(
          `/FatRepresPorLinha?nome_repres=FILHO%20DO%20TRABAL&ano_mes=${data}`,
        )
        .then(async (response) => {
          setExce2(loadDataExce(response.data));
        })
        .catch((error) => {
          console.error(`erro ao receber lista de pedidos${error}`);
        });

      api
        .get(
          `/FatRepresPorLinha?nome_repres=FILHO%20DO%20TRABAL&ano_mes=${dataAnt}`,
        )
        .then(async (response) => {
          await setExce2Ant(loadDataExce(response.data));
        })
        .catch((error) => {
          console.error(`erro ao receber lista de pedidos${error}`);
        });
    }
  }, [refreshKey]);

  const loadDataExce = (data) => {
    // mapeamento e estruturação para os dados no grafico
    const result = _.map(data, (value) => {
      if (value.LINHA === 'COLCHOES') {
        return { LINHA: value.LINHA, TOTAL_MES: value.TOTAL_MES };
      }
    });

    const resultado = result.filter((el) => el !== undefined);

    return resultado;
  };

  return (
    <Container>
      <div className="mainitempedidos" style={{ backgroundColor: '#FFFFFF' }}>
        <div className="itempedidomain1">
          <div className="orderitem__butons2">
            <div style={{ padding: '10px', width: '100%', textAlign: 'start' }}>
              <h5 style={{ fontWeight: 'bolder' }}>
                POSIÇÃO FATURAMENTO: {username}
                {moment(d).format('MMMM/YYYY').toLocaleUpperCase()}
              </h5>
            </div>
            <div className="orderitem__butons1">
              {/* condição para trocar entre gerentes caso o tipo do usuario seja diretoria */}
              {tpuser === 4 ? (
                <div>
                  <select
                    defaultValue={localStorage.getItem('id_gerente')}
                    onChange={(e) => {
                      localStorage.setItem('id_gerente', e.target.value);
                      atualizador();
                    }}
                    className="selectDiretoria"
                  >
                    {gerentes.map((gerentes) => (
                      <option value={gerentes.ID_GERENTE}>
                        {gerentes.GERENTE}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div>
          {pedidos.length > 0 ? (
            <div style={{ padding: '10px' }}>
              <Table striped style={{ verticalAlign: 'middle' }}>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'start' }}>
                      <span>Representante </span>
                    </th>
                    <th>Meta (%)</th>
                    <th>Total Dia</th>
                    <th>Total Mês</th>
                    <th>Mês Ano A</th>
                  </tr>
                </thead>
                <tbody>
                  {pedidos.map((pedido) => (
                    <tr>
                      <td style={{ textAlign: 'start' }}>
                        {/* <Link to={`/faturamento/${pedido.NOME_REP}`}> */}
                        <span className="spanGrafico">{pedido.NOME_REP}</span>
                        <br />
                        <span className="meta">
                          {pedido.META_MES.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </span>
                        {/* </Link> */}
                      </td>
                      <td>
                        <strong>{pedido.PERC_META} % </strong>
                      </td>
                      <td>
                        {pedido.TOTAL_DIA.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          {pedido.TOTAL_MES.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                          <div
                            style={{
                              color:
                                pedido.TOTAL_MES > pedido.MES_ANO_ANT
                                  ? 'green'
                                  : 'red',
                              fontSize: '12px',
                              fontWeight: 'bold',
                            }}
                          >
                            {pedido.TOTAL_MES > pedido.MES_ANO_ANT ? '+' : '-'}
                            {Porcentagem(pedido.TOTAL_MES, pedido.MES_ANO_ANT)}
                          </div>
                        </div>
                      </td>
                      <td>
                        {pedido.MES_ANO_ANT.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                    </tr>
                  ))}
                  {parseInt(idGerente) === 5 &&
                    exce.map((repres) => (
                      <tr key={repres.LINHA}>
                        <td style={{ textAlign: 'start' }}>
                          {/* <Link to="/"> */}
                          <span className="spanGrafico">
                            JOSE GOMES REPR - {repres.LINHA}
                          </span>
                          <br />
                          <span className="meta">R$ 0,00</span>
                          {/* </Link> */}
                        </td>
                        <td>
                          <strong>---</strong>
                        </td>
                        <td>---</td>
                        <td>
                          {repres.TOTAL_MES.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </td>
                        <td>
                          {exceAnt
                            .map((mes) => mes.TOTAL_MES)
                            .toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                        </td>
                      </tr>
                    ))}
                  {parseInt(idGerente) === 5 &&
                    exce2.map((repres) => (
                      <tr key={repres.LINHA}>
                        <td style={{ textAlign: 'start' }}>
                          {/* <Link to="/"> */}
                          <span className="spanGrafico">
                            FILHO DO TRABAL - {repres.LINHA}
                          </span>
                          <br />
                          <span className="meta">R$ 0,00</span>
                          {/* </Link> */}
                        </td>
                        <td>
                          <strong>---</strong>
                        </td>
                        <td>---</td>
                        <td>
                          {repres.TOTAL_MES.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </td>
                        <td>
                          {exce2Ant
                            .map((mes) => mes.TOTAL_MES)
                            .toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                        </td>
                      </tr>
                    ))}
                </tbody>
                <thead style={{ marginBottom: '0 none' }}>
                  <tr>
                    <th style={{ textAlign: 'start' }}>
                      <span>Total Geral</span>
                    </th>
                    <th />
                    <th>
                      {SomaTotal(pedidos).somaTotalDia.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </th>
                    <th>
                      {parseInt(idGerente) === 5
                        ? (
                            parseFloat(SomaTotal(pedidos).somaTotalMes) +
                            parseFloat(exce.map((repres) => repres.TOTAL_MES)) +
                            parseFloat(exce2.map((repres) => repres.TOTAL_MES))
                          ).toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })
                        : SomaTotal(pedidos).somaTotalMes.toLocaleString(
                            'pt-br',
                            { style: 'currency', currency: 'BRL' },
                          )}
                    </th>
                    <th>
                      {parseInt(idGerente) === 5
                        ? (
                            parseFloat(SomaTotal(pedidos).somaTotalAno) +
                            parseFloat(
                              exceAnt.map((repres) => repres.TOTAL_MES),
                            ) +
                            parseFloat(
                              exce2Ant.map((repres) => repres.TOTAL_MES),
                            )
                          ).toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })
                        : SomaTotal(pedidos).somaTotalAno.toLocaleString(
                            'pt-br',
                            { style: 'currency', currency: 'BRL' },
                          )}
                    </th>
                  </tr>
                </thead>
              </Table>
            </div>
          ) : (
            <ClipLoader />
          )}

          {/* <div>
                <div className="chartBar" />
                <div>
                  <Chart
                    chartType="Bar"
                    width="100%"
                    height="700px"
                    data={chartData}
                    options={options}
                  />
                </div>
                <div className="chartBar" />
              </div>
                          */}
        </div>
      </div>
      <div
        style={{ margin: 'auto', display: 'block', backgroundColor: '#FFFFFF' }}
      >
        <ScrollReturn position={200} />
        <div
          style={{
            display: loading ? 'flex' : 'none',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          {/* <ClipLoader
            type="spin"
            color="black"
            size={50}
            className="loading"
            speedMultiplier={1.2}
          /> */}
        </div>
      </div>
    </Container>
  );
}

export default Grafico;
