import Dropdown from 'react-bootstrap/Dropdown';
import { NfNotification, NfIcon, MobileItem } from './styles';

export default function StatusButton({
  bgColor,
  status,
  nf,
  emissao,
  pedidoCliente,
}) {
  return (
    <Dropdown>
      <NfNotification display={nf ? '' : 'none'}>
        <NfIcon />
      </NfNotification>
      <Dropdown.Toggle
        style={{
          backgroundColor: bgColor,
          borderColor: bgColor,
          fontSize: '15px',
          fontWeight: 'bolder ',
        }}
      >
        {status[0]}
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ textAlign: 'center' }}>
        <Dropdown.Item
          style={{ color: bgColor, fontSize: '15px', fontWeight: 'bolder' }}
        >
          {status}
        </Dropdown.Item>
        <div style={{ display: nf ? '' : 'none' }}>
          <Dropdown.Item>
            NF°: <strong>{nf}</strong>
          </Dropdown.Item>
          <Dropdown.Item>{emissao} </Dropdown.Item>
          <Dropdown.Divider />
          <MobileItem>
            Ped.Cliente: <strong> {pedidoCliente}</strong>
          </MobileItem>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
