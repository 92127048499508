import styled from 'styled-components';
import { Pencil, RightArrowAlt } from 'styled-icons/boxicons-regular';
import { Button } from 'react-bootstrap';
import { Save } from 'styled-icons/boxicons-solid';

export const PencilIcon = styled(Pencil)`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ButtonEdit = styled(Button)`
  width: 40px;
  position: relative;
`;

export const SaveIcon = styled(Save)`
  width: 20px;
  height: 20px;
`;

export const RightIcon = styled(RightArrowAlt)`
  width: 20px;
  height: 20px;
`;
