function DateSet() {
  // estabelecendo datas para os graficos

  const date = new Date();
  const currentYear = date.getFullYear();
  const currentYearAnterior = date.getFullYear() - 1;
  const monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  const currentMes = monthNames[date.getMonth()].substring(0, 3).toUpperCase();
  const antMes = monthNames[date.getMonth() - 1].substring(0, 3).toUpperCase();
  const MesAnt = date.getMonth();
  const mesAtual = monthNames[date.getMonth()].substring(0, 3).toUpperCase();
  const currentMonth = date.getMonth() + 1;
  let currentMonthFormat = '';
  if (currentMonth < 10) {
    currentMonthFormat = `0${currentMonth}`;
  }
  const currentYearMonth = currentYear + currentMonthFormat;
  let currentMonthAntFormat = date.getMonth();
  if (currentMonth < 10) {
    currentMonthAntFormat = `0${date.getMonth()}`;
  }
  const currentYearMonthAnt = currentYear + currentMonthAntFormat;

  return {
    date,
    currentMes,
    currentYear,
    currentYearAnterior,
    antMes,
    MesAnt,
    mesAtual,
    currentYearMonth,
    currentYearMonthAnt,
  };
}

export default DateSet;
