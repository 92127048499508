import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --primary: #F0F0F0;
    --secondary: #191C24;
    --outline: #6C7293;
    --success: #02C758;
    --warning: #FFAB00;
    --danger: #FC424A;
    --white: #FFFFFF;
    --purple: #8F5FE8;
    --info: #0090E7;
    --infoOuter: #17a2b8;  } 
`;
