/* eslint-disable no-nested-ternary */
/* eslint-disable no-inner-declarations */
/* eslint-disable prefer-destructuring */
import './Orderadd.css';
import React, { useState } from 'react';
import { Button, Form, InputGroup, Row, Col } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';
import api from '../../../services/api/axios';
import SalvarRascunho from '../SalvarRascunho';
import 'react-toastify/dist/ReactToastify.css';
import { ButtonEdit, PencilIcon, SaveIcon, RightIcon } from './styles.js';
import LimparDados from '../LimparDados';

function Orderadd() {
  const [confirma, setConfirma] = useState(false);
  const [msgerro, setMsgerro] = useState('');
  const [mensagem, setMensagem] = useState(false);

  // importando os dados do localStorage
  const clie = JSON.parse(localStorage.getItem('clicomp'));
  const clief = JSON.parse(localStorage.getItem('clieentr'));
  const cliec = JSON.parse(localStorage.getItem('cliefat'));
  const repr = JSON.parse(localStorage.getItem('repres'));
  const condpgto = JSON.parse(localStorage.getItem('condpgto'));
  const transp = JSON.parse(localStorage.getItem('transp'));
  const verifica = parseInt(localStorage.getItem('parcial'));

  const navegador = useNavigate();

  let cliente = '';
  let cnpj = '';
  let clienteF = '';
  let cnpjF = '';
  let clienteC = '';
  let cnpjC = '';
  let nomerepre = '';
  let nomecondpgto = '';
  let nometransp = '';

  if (repr) {
    nomerepre = repr[1];
  }
  if (condpgto) {
    nomecondpgto = condpgto[1];
  }
  if (transp) {
    nometransp = transp[2];
  }
  if (clie) {
    cliente = clie[2];
    cnpj = clie[1];
  }
  if (clief) {
    clienteF = clief[2];
    cnpjF = clief[1];
  }
  if (cliec) {
    clienteC = cliec[2];
    cnpjC = cliec[1];
  }

  function EnviarPedido() {
    if (localStorage.getItem('clicomp') === null) {
      setMsgerro('Informar cliente comprador');
    } else if (localStorage.getItem('clieentr') === null) {
      setMsgerro('Informar cliente entrega.');
    } else if (localStorage.getItem('cliefat') === null) {
      setMsgerro('Informar cliente faturamento.');
    } else if (localStorage.getItem('repres') === null) {
      setMsgerro('Informar representente');
    } else if (localStorage.getItem('condpgto') === null) {
      setMsgerro('Informar condição de pagamento.');
    } else if (localStorage.getItem('transp') === null) {
      setMsgerro('Informar transportadora');
    } else if (localStorage.getItem('jsonitens') === null) {
      setMsgerro('Nenhum item foi adicionado ao pedido.');
    } else {
      const formatTwoDigits = (digit) => `0${digit}`.slice(-2);
      const tempDate = new Date();
      const datanumber =
        tempDate.getFullYear() +
        formatTwoDigits(tempDate.getMonth() + 1) +
        formatTwoDigits(tempDate.getDate());

      // pegar a lista de itens do temp.
      const ItensLocalStorage = JSON.parse(localStorage.getItem('jsonitens'));

      // criar modelo de um item do pedido
      let UmItemPedido = {
        Reduzido: 0,
        CodComercial: '',
        Descricao: '',
        UMPadrao: 0,
        IDUMDIGITACAO: 0,
        UMPedido: 0,
        Largura: 0,
        Quantidade: 0,
        ValorUitario: 0,
        ValorTotal: 0,
        StatusIt: 0,
        ItemCliente: '',
        OBS: '',
        EXPEDIREM: 0,
        ACONDICIONAMENTO: 0,
        DIMENSAO: 0,
        NAO_ENTREGAR_ANTES: '',
      };

      function trocarpFloat(value) {
        const valorRep = value.replace(',', '.');
        const valorFloat = parseFloat(valorRep);
        return valorFloat;
      }

      let totalpedido = 0;
      // montar json com os itens ler dados do local storage e criar um item no array criar o modelo do cabeçalho do pedido e adiciona o array de itens
      const ITENS_PEDIDO = [];
      // eslint-disable-next-line array-callback-return
      ItensLocalStorage.map((item) => {
        UmItemPedido.Reduzido = item.REDUZIDO;
        UmItemPedido.CodComercial = item.COD_COM;
        UmItemPedido.Descricao = item.DESCRICAO;
        UmItemPedido.UMPadrao = item.ID_UM_PADRAO;
        UmItemPedido.IDUMDIGITACAO = item.ID_UM;
        UmItemPedido.UMPedido = item.ID_UM;
        UmItemPedido.Largura = item.LARGURA;
        UmItemPedido.Quantidade = trocarpFloat(item.QTDE);
        UmItemPedido.ValorUitario = trocarpFloat(item.PRECO);
        UmItemPedido.ValorTotal =
          item.PRECO.replace(',', '.') * item.QTDE.replace(',', '.');
        UmItemPedido.StatusIt = 0;
        UmItemPedido.ItemCliente = 0;
        UmItemPedido.OBS = item.OBS;
        UmItemPedido.EXPEDIREM = item.EXPEDIREM;
        UmItemPedido.NAO_ENTREGAR_ANTES = item.NAO_ENTREGAR_ANTES;
        UmItemPedido.ACONDICIONAMENTO = 0;
        UmItemPedido.DIMENSAO = 0;
        ITENS_PEDIDO.push(UmItemPedido);
        totalpedido +=
          item.PRECO.replace(',', '.') * item.QTDE.replace(',', '.');

        UmItemPedido = {};
      });
      // parcial
      const Observacao =
        localStorage.getItem('parcial') === '1'
          ? `Aceita Parcial:${localStorage.getItem('qntsentregas')}` === null
            ? '2 entregas'
            : `${localStorage.getItem('qntsentregas')} \n`
          : ' ';
      // comissão
      const percomi =
        JSON.parse(localStorage.getItem('repres'))[2] === null
          ? 5
          : JSON.parse(localStorage.getItem('repres'))[2];
      const comivalor = ((totalpedido * percomi) / 100)
        .toString()
        .replace('.', ',');
      const arraypedidos = [];
      // montando a estrutura do pedido para enviar para a API
      const pedido = {
        ACEITATOLERANCIAQTD: '1',
        BANCO: 1,
        BLOQUEIOFATURAMENTO: 2,
        CANAL_VENDAS: 0,
        CDFILIAL: 1,
        FL_IMPRESSO: '1',
        GERADODOROMANEIO: 'N',
        DIAPAGAMENTO: 0,
        IDRAMOATIVIDADE: 0,
        PERCCOMISSAOBAIXA: 50,
        PERCCOMISSAOFATURA: 0,
        PEDIDO: 0,
        SITUACAO: 0,
        SITUACAO_ANALITICA: 0,
        TABELAPRECO: 0,
        TOTALPEDIDOBRUTO: 0,
        PED_REP_ORIGEM: '',
        QUANTIDADEKILOS: 0,
        QUANTIDADEMETROS: 0,
        IDREPRESENT_INTERNO: 0,
        DTDATABASE: 0,
        // passando de string do localStorage p/ parseInt, caso a informação seja nulo retorna o valor de 1
        CDFORMPAGA: parseInt(
          localStorage.getItem('frmpgto') === null
            ? 1
            : localStorage.getItem('frmpgto'),
        ),
        CONDICAO_PAGAMENTO: JSON.parse(localStorage.getItem('condpgto'))[0],
        CONDICAO_PRECO: JSON.parse(localStorage.getItem('condpgto'))[0],
        IDFILIALENTREGA: JSON.parse(localStorage.getItem('clieentr'))[0],
        IDFILIALRESPONSAVEL: JSON.parse(localStorage.getItem('clicomp'))[0],
        IDFILIALCOBRANCA: JSON.parse(localStorage.getItem('cliefat'))[0],
        IDREPRESENTANTE: JSON.parse(localStorage.getItem('repres'))[0],
        IDTRANSPORTADORA: JSON.parse(localStorage.getItem('transp'))[0],
        IDREDESPACHO: JSON.parse(localStorage.getItem('transp'))[0],
        OBS:
          Observacao +
          (localStorage.getItem('observ') === null
            ? ''
            : localStorage.getItem('observ')),

        PEDIDOCLIENTE:
          localStorage.getItem('pedcliente') === null
            ? ''
            : localStorage.getItem('pedcliente'),
        PEDIDOREPRESENTANTE:
          localStorage.getItem('pedrepres') === null
            ? ''
            : localStorage.getItem('pedrepres'),
        // passando de string do localStorage p/ INT, caso a informação seja nulo retorna o valor de 0
        TIPOPEDIDO: parseInt(
          localStorage.getItem('tpped') === null
            ? 0
            : localStorage.getItem('tpped'),
        ),
        RECEBIDOEM: datanumber,
        // alterando valores se for nulo para 0
        TIPOFRETE:
          localStorage.getItem('tpfrete') === null
            ? 1
            : parseInt(localStorage.getItem('tpfrete')),
        TIPOFRETEREDESPACHO:
          localStorage.getItem('tpfrete') === null
            ? 1
            : parseInt(localStorage.getItem('tpfrete')),
        TOTALPEDIDO: totalpedido.toString().replace('.', ','),
        COMISSAO: JSON.parse(localStorage.getItem('repres'))[2],
        COMISSAOVALOR: comivalor,
        PARCIAL: parseInt(
          localStorage.getItem('parcial') === null
            ? 0
            : localStorage.getItem('parcial'),
        ),

        ITENS_PEDIDO,
      };

      arraypedidos.push(pedido);

      console.log(arraypedidos);
      // setConfirma(true);

      // enviado os dados da Array para a API
      api
        .get(`UploadPedidos?json_pedidos=${JSON.stringify(arraypedidos)}`)
        .then(async (respose) => {
          console.log(respose.data);
          await console.log('novo pedido: ', respose.data[0]);
          if (respose.data[0].RESULT === 'OK') {
            setConfirma(true);
          }
        })
        .catch((err) => console.log('Erro: ', err));

      setTimeout(() => {
        LimparDados();
      }, 1000);
    }
  }

  function analisador(value) {
    if (value === '1') {
      setMensagem(true);
    } else {
      setMensagem(false);
    }
  }

  const customId = 'custom-id-rascunhoSalvo';

  const notify = () => {
    toast.success(`Rascunho salvo`, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customId,
    });
  };

  return (
    <div className="containerMain">
      <div className="orderadd__main">
        <div className="orderadd__client">
          <form className="orderadd__form">
            <div className="orderadd__tittle">
              <Form>
                <Form.Group style={{ marginTop: '10px' }}>
                  <Form.Label>Cliente Comprador</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      value={`${cliente} / ${cnpj}`}
                      aria-describedby="button-addon2"
                      readOnly
                    />
                    <ButtonEdit onClick={() => navegador('/buscarcliente')}>
                      <PencilIcon />
                    </ButtonEdit>
                  </InputGroup>
                </Form.Group>
                <Form.Group style={{ marginTop: '10px' }}>
                  <Form.Label>Cliente Entrega</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      value={`${clienteF} / ${cnpjF}`}
                      aria-describedby="button-addon2"
                      readOnly
                    />
                    <ButtonEdit onClick={() => navegador('/buscarclienteentr')}>
                      <PencilIcon />
                    </ButtonEdit>
                  </InputGroup>
                </Form.Group>
                <Form.Group style={{ marginTop: '10px' }}>
                  <Form.Label>Cliente Fatura</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      value={`${clienteC} / ${cnpjC}`}
                      aria-describedby="button-addon2"
                      readOnly
                    />
                    <ButtonEdit onClick={() => navegador('/buscarclientefat')}>
                      <PencilIcon />
                    </ButtonEdit>
                  </InputGroup>
                </Form.Group>
              </Form>
              {/* <i className="fa fa-address-book" />
              <span className="ordeadd__icon">Cliente Comprador</span>
              <input
                type="text"
                value={`${cliente} / ${cnpj}`}
                aria-describedby="button-addon2"
                disabled
              />
              <Link to="/buscarcliente">
                <i className="fa fa-pencil" />
              </Link> */}
            </div>
          </form>
        </div>
        <div className="orderadd__client">
          <form className="orderadd__form">
            <div className="orderadd__tittle">
              {/* <i className="fa fa-address-book" />
              <span className="ordeadd__icon">Cliente Entrega</span>
              <input
                type="text"
                value={`${clienteF} / ${cnpjF}`}
                aria-describedby="button-addon2"
                disabled
              />
              <Link to="/buscarclienteentr">
                <i className="fa fa-pencil" />
              </Link> */}
            </div>
          </form>
        </div>
        <div className="orderadd__client">
          {/* <form className="orderadd__form">
            <div className="orderadd__tittle">
              <i className="fa fa-address-book" />
              <span className="ordeadd__icon">Cliente Fatura</span>
              <input
                type="text"
                value={`${clienteC} / ${cnpjC}`}
                aria-describedby="button-addon2"
                disabled
              />
              <Link to="/buscarclientefat">
                <i className="fa fa-pencil" />
              </Link>
            </div>
          </form> */}
        </div>
        <div className="orderadd__menuselect">
          {/* <div className="orderadd__menu"> */}
          <Form>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>Representante</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    value={nomerepre}
                    aria-describedby="button-addon2"
                    readOnly
                  />
                  <ButtonEdit onClick={() => navegador('/buscarepresentante')}>
                    <PencilIcon />
                  </ButtonEdit>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Tipo do Pedido:</Form.Label>
                <Form.Select
                  id="cbxTipoFrete"
                  defaultValue={localStorage.getItem('tpped')}
                  className="frete"
                  aria-label=".form-select-lg example"
                  onChange={(e) =>
                    localStorage.setItem('tpped', e.target.value)
                  }
                >
                  <option value="0">VENDA</option>
                  <option value="6">AMOSTRA</option>
                </Form.Select>
              </Form.Group>
            </Row>
          </Form>
          {/* <span>Representante: </span>
            <input
              type="text"
              value={nomerepre}
              aria-describedby="button-addon2"
              disabled
            />
            <Link to="/buscarepresentante">
              <button type="button" className="clicksearch">
                <i className="fa fa-pencil" />
              </button>
            </Link> */}
          {/* </div> */}
          {/* <div className="orderadd__menu">
            <span>Tipo do Pedido: </span>
            <select
              id="cbxTipoFrete"
              defaultValue={localStorage.getItem('tpped')}
              className="frete"
              aria-label=".form-select-lg example"
              onChange={(e) => localStorage.setItem('tpped', e.target.value)}
            >
              <option value="0">VENDA</option>
              <option value="6">AMOSTRA</option>
            </select>
          </div> */}
          {/* <div className="orderadd__menu"> */}
          <Form style={{ marginTop: '10px' }}>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>Pedido Representante:</Form.Label>
                <Form.Control
                  name="pedrep"
                  className="pedrep"
                  onBlur={(e) => {
                    localStorage.setItem('pedrepres', e.target.value);
                  }}
                  type="text"
                  defaultValue={localStorage.getItem('pedrepres')}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Pedido Cliente:</Form.Label>
                <Form.Control
                  name="pedcli"
                  className="pedrep"
                  onBlur={(e) => {
                    localStorage.setItem('pedcliente', e.target.value);
                    if (e.target.value) {
                      navegador('/verificarpedcli');
                    }
                  }}
                  type="text"
                  defaultValue={localStorage.getItem('pedcliente')}
                />
              </Form.Group>
            </Row>
          </Form>
          {/* <span>Pedido Representante: </span>
            <input
              name="pedrep"
              className="pedrep"
              onBlur={(e) => {
                localStorage.setItem('pedrepres', e.target.value);
              }}
              type="text"
              defaultValue={localStorage.getItem('pedrepres')}
            />
          </div>
          <div className="orderadd__menu">
            <span>Pedido Cliente: </span>
            <input
              name="pedcli"
              className="pedrep"
              onBlur={(e) => {
                localStorage.setItem('pedcliente', e.target.value);
                if (e.target.value) {
                  navegador('/verificarpedcli');
                }
              }}
              type="text"
              defaultValue={localStorage.getItem('pedcliente')}
            /> */}
          {/* </div> */}
          <Form>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>Forma de Pagamento:</Form.Label>
                <Form.Select
                  id="cbxTipoFrete"
                  defaultValue={localStorage.getItem('frmpgto')}
                  className="frete"
                  aria-label=".form-select-lg example"
                  onChange={(e) =>
                    localStorage.setItem('frmpgto', e.target.value)
                  }
                >
                  <option value="1">DUPLICATA</option>
                  <option value="2">DINHEIRO</option>
                  <option value="3">CARTÃO DE CRÉDITO</option>
                  <option value="4">CARTÃO BNDS</option>
                  <option value="5">CARTA DE CRÉDITO</option>
                  <option value="6">TRANSFERENCIA BANCARIA</option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Condição de Pagamento:</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    value={nomecondpgto}
                    aria-describedby="button-addon2"
                    readOnly
                  />
                  <ButtonEdit onClick={() => navegador('/buscacondpgto')}>
                    <PencilIcon />
                  </ButtonEdit>
                </InputGroup>
              </Form.Group>
            </Row>
          </Form>
          {/* <div className="orderadd__menu">
            <span>Forma de Pagamento: </span>
            <select
              id="cbxTipoFrete"
              defaultValue={localStorage.getItem('frmpgto')}
              className="frete"
              aria-label=".form-select-lg example"
              onChange={(e) => localStorage.setItem('frmpgto', e.target.value)}
            >
              <option value="1">DUPLICATA</option>
              <option value="2">DINHEIRO</option>
              <option value="3">CARTÃO DE CRÉDITO</option>
              <option value="4">CARTÃO BNDS</option>
              <option value="5">CARTA DE CRÉDITO</option>
              <option value="6">TRANSFERENCIA BANCARIA</option>
            </select>
          </div> */}
          {/* <div className="orderadd__menu">
            <span>Condição de Pagamento: </span>
            <input
              type="text"
              value={nomecondpgto}
              aria-describedby="button-addon2"
              disabled
            />
            <Link to="/buscacondpgto">
              <button type="button" className="clicksearch">
                <i className="fa fa-pencil" />
              </button>
            </Link>
          </div> */}
          <Form>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>Transportadora</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    value={nometransp}
                    aria-describedby="button-addon2"
                    readOnly
                  />
                  <ButtonEdit onClick={() => navegador('/buscartransp')}>
                    <PencilIcon />
                  </ButtonEdit>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Frete:</Form.Label>
                <Form.Select
                  id="cbxTipoFrete"
                  defaultValue={localStorage.getItem('tpfrete')}
                  className="frete"
                  aria-label=".form-select-lg example"
                  onChange={(e) =>
                    localStorage.setItem('tpfrete', e.target.value)
                  }
                >
                  <option value="1">FOB</option>
                  <option value="0">CIF</option>{' '}
                </Form.Select>
              </Form.Group>
            </Row>
          </Form>
          {/* <div className="orderadd__menu">
            <span>Transportadora: </span>
            <input
              type="text"
              value={nometransp}
              aria-describedby="button-addon2"
              disabled
            />
            <Link to="/buscartransp">
              <button type="button" className="clicksearch">
                <i className="fa fa-pencil" />
              </button>
            </Link>
          </div> */}
          {/* <div className="orderadd__menu">
            <span>Frete: </span>
            <select
              id="cbxTipoFrete"
              defaultValue={localStorage.getItem('tpfrete')}
              className="frete"
              aria-label=".form-select-lg example"
              onChange={(e) => localStorage.setItem('tpfrete', e.target.value)}
            >
              <option value="1">FOB</option>
              <option value="0">CIF</option>
            </select>
          </div> */}
          <Form>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>Parcial:</Form.Label>
                <Form.Select
                  id="cbxPacial"
                  defaultValue={localStorage.getItem('parcial')}
                  className="frete"
                  aria-label=".form-select-lg example"
                  onChange={(e) => {
                    localStorage.setItem('parcial', e.target.value);
                    analisador(e.target.value);
                  }}
                >
                  <option value="0">NÃO</option>
                  <option value="1">SIM</option>
                </Form.Select>
              </Form.Group>
              {mensagem === true || verifica === 1 ? (
                <Form.Group as={Col}>
                  <Form.Label>Quantidade de Entregas:</Form.Label>
                  <Form.Select
                    id="cbxPacial"
                    defaultValue={localStorage.getItem('qntsentregas')}
                    className="frete"
                    aria-label=".form-select-lg example"
                    onChange={(e) => {
                      localStorage.setItem('qntsentregas', e.target.value);
                    }}
                  >
                    <option value="2 Entregas">2 Entregas</option>
                    <option value="3 Entregas">3 Entregas</option>
                  </Form.Select>
                </Form.Group>
              ) : // <div className="orderadd__menu">
              //   <span>Quantidade de Entregas: </span>
              //   <select
              //     id="cbxPacial"
              //     defaultValue={localStorage.getItem('qntsentregas')}
              //     className="frete"
              //     aria-label=".form-select-lg example"
              //     onChange={(e) => {
              //       localStorage.setItem('qntsentregas', e.target.value);
              //     }}
              //   >
              //     <option value="2 Entregas">2 Entregas</option>
              //     <option value="3 Entregas">3 Entregas</option>
              //   </select>
              // </div>
              null}
            </Row>
          </Form>
          {/* <div className="orderadd__menu">
            <span>Parcial: </span>
            <select
              id="cbxPacial"
              defaultValue={localStorage.getItem('parcial')}
              className="frete"
              aria-label=".form-select-lg example"
              onChange={(e) => {
                localStorage.setItem('parcial', e.target.value);
                analisador(e.target.value);
              }}
            >
              <option value="0">NÃO</option>
              <option value="1">SIM</option>
            </select>
          </div> */}
        </div>
        <Form>
          <Form.Group>
            <Form.Label>Observações:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              id="textObservPed"
              onBlur={(e) => {
                localStorage.setItem('observ', e.target.value);
              }}
              defaultValue={localStorage.getItem('observ')}
            />
          </Form.Group>
        </Form>
        {/* <div className="orderadd__menu">
          <span>Observações: </span>
          <textarea
            type="text"
            id="textObservPed"
            onBlur={(e) => {
              localStorage.setItem('observ', e.target.value);
            }}
            defaultValue={localStorage.getItem('observ')}
          />
        </div> */}
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '20px',
          }}
        >
          <Button
            type="button"
            disabled={!localStorage.getItem('jsonitens')}
            variant="success"
            onClick={() => {
              EnviarPedido();
            }}
          >
            Enviar Pedido
          </Button>
          <Button
            variant="dark"
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              width: '120px',
              height: '40px',
            }}
            onClick={() => navegador('/Itensdopedido')}
          >
            <span
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                marginRight: '2px',
              }}
            >
              Itens
            </span>
            <RightIcon
              style={{ display: 'inline-block', verticalAlign: 'middle' }}
            />
          </Button>
        </div>
        {/* <div className="Buttons"> */}
        {/* {localStorage.getItem('jsonitens') ? (
            <button
              type="button"
              className="buttonCopy"
              onClick={() => {
                EnviarPedido();
              }}
            >
              <i className="fa fa-check" />
              Enviar Pedido
            </button>
          ) : (
            <button
              type="button"
              className="buttonCopy"
              disabled
              style={{ backgroundColor: 'grey' }}
              onClick={() => {
                EnviarPedido();
              }}
            >
              <i className="fa fa-check" />
              Enviar Pedido
            </button>
          )} */}
        {/* <Link to="/rascunho">
            <button
              type="button"
              className="buttonItens"
              onClick={() => {
                SalvarRascunho();
                notify();
              }}
            >
              Rascunho
              <i className="fa fa-floppy-o" />
            </button>
          </Link> */}
        {/* <Link to="/Itensdopedido">
            <button type="button" className="buttonItens">
              Itens do Pedido
              <i className="fa fa-arrow-circle-right" />
            </button>
          </Link>
        </div> */}
      </div>
      {/* Alerta de Sucesso ao envio do pedido */}
      {confirma ? (
        <SweetAlert
          success
          title="successo!"
          customButtons={
            <Link to="/Pedidos">
              <button
                type="button"
                src="./itempedido.css"
                className="buttonalert__confirm"
              >
                OK
              </button>
            </Link>
          }
        >
          pedido enviado com sucesso!
        </SweetAlert>
      ) : null}
      {/* Alerta de que falta dados para conclusão do envio do pedido */}
      {msgerro.length > 0 ? (
        <SweetAlert
          warning
          title="Erro!"
          onConfirm={() => setMsgerro(false)}
          customButtons={
            <button
              type="button"
              src="./itempedido.css"
              className="buttonalert__confirm"
              onClick={() => {
                setMsgerro(false);
              }}
            >
              OK
            </button>
          }
        >
          <div>Faltam dados para enviar o pedido:</div>
          <div style={{ color: 'tomato' }}>{msgerro}</div>
        </SweetAlert>
      ) : null}
    </div>
  );
}

export default Orderadd;
