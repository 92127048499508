function SalvarRascunho() {
  let Pedidos = [];
  const ped = JSON.parse(localStorage.getItem('listaPedidos'));
  if (ped) {
    Pedidos = [...ped];
  }

  const date = new Date().toLocaleString();

  const PRODUTOS = JSON.parse(localStorage.getItem('jsonitens'));

  const pedido = {
    CLIENTECOMP: JSON.parse(localStorage.getItem('clicomp')),
    CLIENTEENTR: JSON.parse(localStorage.getItem('clieentr')),
    CLIENTEFAT: JSON.parse(localStorage.getItem('cliefat')),
    REPRESENTANTE: JSON.parse(localStorage.getItem('repres')),
    TPPED: JSON.parse(localStorage.getItem('tpped')),
    FORMAPGTO: JSON.parse(localStorage.getItem('frmpgto')),
    CONDPGTO: JSON.parse(localStorage.getItem('condpgto')),
    TRANSP: JSON.parse(localStorage.getItem('transp')),
    TIPOFRETE: JSON.parse(localStorage.getItem('tpfrete')),
    PARCIAL: JSON.parse(localStorage.getItem('parcial')),
    PEDREPRESENTANTE: localStorage.getItem('pedrepres'),
    PEDCLIENTE: localStorage.getItem('pedcliente'),
    OBS: localStorage.getItem('observ'),
    PACIAL: localStorage.getItem('parcial'),
    ITENS:
      JSON.parse(localStorage.getItem('jsonitens')) === null
        ? []
        : JSON.parse(localStorage.getItem('jsonitens')),
    VALORTOTAL:
      PRODUTOS === null
        ? null
        : PRODUTOS.map((item) => item.PRECO * item.QTDE).reduce(
            (prev, curr) => prev + curr,
            0,
          ),
    DATARASCUNHO: date,
  };

  Pedidos.push(pedido);

  localStorage.setItem('listaPedidos', JSON.stringify(Pedidos));
}

export default SalvarRascunho;
