import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import api from '../../../services/api/axios';
import Descformpagt from '../Descricaoformpagt';
import { ButtonEdit, RightIcon } from '../Formulario/styles';
import { LeftIcon } from './styles';

function ConsultaPed() {
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(true);

  // importando o endpoint da URL
  const { id } = useParams();

  const nave = useNavigate();

  // Procurando na API detalhes conforme o parametro
  useEffect(() => {
    api.get(`DetalhesPedido?pedido=${id}`).then(async (resultado) => {
      setDados(resultado.data);
      if (resultado.data.length > 0) {
        setLoading(false);
      }
    });
  }, []);

  return (
    <div className="containerMain" style={{ backgroundImage: 'none' }}>
      {dados.map((dados) => (
        <div className="orderadd__main" key={dados.NUMERO_PRE_PEDIDO}>
          {/* <div className="orderad+d__client"> */}
          <Form>
            {/* <div className="orderadd__tittle" key={dados.NUMERO_PRE_PEDIDO}> */}
            <Form.Group style={{ marginTop: '10px' }}>
              <Form.Label>Cliente Comprador</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  defaultValue={`${dados.CLIENTE} / ${dados.CNPJ}`}
                  aria-describedby="button-addon2"
                  readOnly
                />
              </InputGroup>
            </Form.Group>
            {/* <i className="fa fa-address-book" />
                <span className="ordeadd__icon">Cliente Comprador</span>
                <input
                  type="text"
                  defaultValue={`${dados.CLIENTE} / ${dados.CNPJ}`}
                  aria-describedby="button-addon2"
                  disabled
                /> */}
            {/* </div> */}
          </Form>
          {/* </div> */}
          {/* <div className="orderadd__client"> */}
          {/* <form className="orderadd__form"> */}
          {/* <div className="orderadd__tittle"> */}
          <Form>
            <Form.Group style={{ marginTop: '10px' }}>
              <Form.Label>Cliente Comprador</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  defaultValue={`${dados.CLIENTE_ENTR} / ${dados.CNPJ_ENTR}`}
                  aria-describedby="button-addon2"
                  readOnly
                />
              </InputGroup>
            </Form.Group>
          </Form>
          {/* <i className="fa fa-address-book" />
                <span className="ordeadd__icon">Cliente Entrega</span>
                <input
                  type="text"
                  defaultValue={`${dados.CLIENTE_ENTR} / ${dados.CNPJ_ENTR}`}
                  aria-describedby="button-addon2"
                  disabled
                /> */}
          {/* </div> */}
          {/* </form> */}
          {/* </div> */}
          {/* <div className="orderadd__client"> */}
          <Form>
            <Form.Group style={{ marginTop: '10px' }}>
              <Form.Label>Cliente Comprador</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  defaultValue={`${dados.CLIENTE_RESP} / ${dados.CNPJ_RESP}`}
                  aria-describedby="button-addon2"
                  readOnly
                />
              </InputGroup>
            </Form.Group>
          </Form>
          {/* <form className="orderadd__form">
              <div className="orderadd__tittle">
                <i className="fa fa-address-book" />
                <span className="ordeadd__icon">Cliente Fatura</span>
                <input
                  type="text"
                  defaultValue={`${dados.CLIENTE_RESP} / ${dados.CNPJ_RESP}`}
                  disabled
                />
              </div>
            </form> */}
          {/* </div> */}
          <div className="orderadd__menuselect">
            <Form>
              <Row>
                <Form.Group as={Col}>
                  <Form.Label>Representante</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      value={dados.REPRESENTANTE}
                      readOnly
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Tipo do Pedido:</Form.Label>
                  <Form.Control
                    type="text"
                    value={dados.TIPO_PEDIDO}
                    readOnly
                  />
                </Form.Group>
              </Row>
            </Form>

            {/* <span>Representante:</span>
              <input type="text" defaultValue={dados.REPRESENTANTE} disabled /> */}
            {/* <div className="orderadd__menu">
              <span>Tipo do Pedido: </span>
              <input type="text" defaultValue={dados.TIPO_PEDIDO} disabled />
            </div> */}
            <Form>
              <Row>
                <Form.Group as={Col}>
                  <Form.Label>Pedido Representante:</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      value={dados.PEDIDOREPRESENTANTE}
                      readOnly
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Pedido Cliente: </Form.Label>
                  <Form.Control
                    type="text"
                    value={dados.PEDIDOCLIENTE}
                    readOnly
                  />
                </Form.Group>
              </Row>
            </Form>
            {/* <div className="orderadd__menu">
              <span>Pedido Representante: </span>
              <input
                type="text"
                defaultValue={dados.PEDIDOREPRESENTANTE}
                disabled
              />
            </div>
            <div className="orderadd__menu">
              <span>Pedido Cliente: </span>
              <input type="text" defaultValue={dados.PEDIDOCLIENTE} disabled />
            </div> */}
            <Form>
              <Row>
                <Form.Group as={Col}>
                  <Form.Label>Forma de Pagamento:</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      value={Descformpagt(dados.CDFORMPAGA)}
                      readOnly
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Condição de Pagamento: </Form.Label>
                  <Form.Control
                    type="text"
                    value={dados.DESCR_COND_PAGTO}
                    readOnly
                  />
                </Form.Group>
              </Row>
            </Form>
            {/* <div className="orderadd__menu">
              <span>Forma de Pagamento: </span>
              <input
                type="text"
                defaultValue={Descformpagt(dados.CDFORMPAGA)}
                disabled
              />
            </div>
            <div className="orderadd__menu">
              <span>Condição de Pagamento: </span>
              <input
                type="text"
                defaultValue={dados.DESCR_COND_PAGTO}
                disabled
              />
            </div> */}
            <Form>
              <Row>
                <Form.Group as={Col}>
                  <Form.Label>Transportadora:</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      value={dados.TRANSPORTADORA}
                      readOnly
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Frete: </Form.Label>
                  <Form.Control
                    type="text"
                    value={dados.DESCR_TIPO_FRETE}
                    readOnly
                  />
                </Form.Group>
              </Row>
            </Form>
            {/* <div className="orderadd__menu">
              <span>Transportadora: </span>
              <input type="text" defaultValue={dados.TRANSPORTADORA} disabled />
            </div>
            <div className="orderadd__menu">
              <span>Frete: </span>
              <input
                type="text"
                defaultValue={dados.DESCR_TIPO_FRETE}
                aria-describedby="button-addon2"
                disabled
              />
            </div> */}
          </div>

          <Form>
            <Form.Group>
              <Form.Label>Observações:</Form.Label>
              <Form.Control
                as="textarea"
                readOnly
                rows={3}
                defaultValue={dados.OBS}
              />
            </Form.Group>
          </Form>
          {/* <div className="orderadd__menu">
            <span>Observações: </span>
            <textarea type="text" defaultValue={dados.OBS} />
          </div> */}
          {/* <div className="Buttons"> */}
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: '10px',
            }}
          >
            <Button
              type="button"
              onClick={() => {
                nave('/Pedidos');
              }}
              variant="dark"
              style={{ width: '120px', height: '40px' }}
            >
              <LeftIcon
                style={{ display: 'inline-block', verticalAlign: 'middle' }}
              />

              <span
                style={{
                  display: 'inline-block',
                  verticalAlign: 'middle',
                }}
              >
                Pedidos
              </span>
            </Button>
            {/* <Link to={`/consultaitem/${dados.NUMERO_PRE_PEDIDO}`}> */}
            <Button
              type="button"
              onClick={() => {
                nave(`/consultaitem/${dados.NUMERO_PRE_PEDIDO}`);
              }}
              variant="dark"
              style={{ width: '120px', height: '40px' }}
            >
              Itens <RightIcon />
            </Button>
            {/* </Link> */}
          </div>
        </div>
      ))}
      <div
        style={{
          width: '100%',
          display: loading ? 'flex' : 'none',
          justifyContent: 'center',
          marginTop: '10px',
        }}
      >
        <ClipLoader
          type="spin"
          color="black"
          size={50}
          className="loading"
          speedMultiplier={1.2}
        />
      </div>
    </div>
  );
}

export default ConsultaPed;
