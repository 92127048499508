/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/function-component-definition */
import randomColor from 'randomcolor';
import React, { useState, useEffect, createContext } from 'react';

import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import { createSession } from '../services/api/axios';

import 'react-toastify/dist/ReactToastify.css';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // utilizando navigate
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const salvoUsuario = localStorage.getItem('check');
  const logedUser = localStorage.getItem('user');
  const idRepresentante = localStorage.getItem('idrep');

  const customId = 'custom-id-logout';
  const customIdLogin = 'custom-id-login';

  const notify = () => {
    toast.error(`Deslogado`, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customId,
    });
  };

  const notifylogin = () => {
    toast.success(`Logado com sucesso`, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customIdLogin,
    });
  };

  console.log(JSON.parse(salvoUsuario));

  function LimparDados() {
    localStorage.clear();
  }

  // deixar logado
  useEffect(() => {
    if (
      JSON.parse(salvoUsuario) === true &&
      logedUser &&
      JSON.parse(idRepresentante) > 0
    ) {
      setUser({ logedUser });
      navigate(
        localStorage.getItem('id_gerente') > 0 ? '/graficos' : '/Pedidos',
      );
    } else {
      LimparDados();
      setUser(null);
      navigate('/');
      notify();
    }
    setLoading(false);
  }, []);

  // trigger login
  const login = async (usuario, senha) => {
    // trazendo os dados da consulta
    const response = await createSession(usuario, senha);
    // passando os dados da API para a variavel e passando de JSON para String
    const idrep = JSON.stringify(response.data);
    // setando o usuario como logado quando recebe o localStorage user
    // se o usuario estiver autorizado, ira para a tela de Home
    // se os dados de login retornar uma array, o usuario sera logado
    if (!response.data.length === false) {
      setUser({ logedUser });
      // ---------tratando os dados recebidos da API----------------------
      // quebrando os dados em array a partir da " , "
      const resultado = idrep.split(',');
      // transformando os dados em string
      const tratamento = resultado.toString();
      // quebrando os dados em array a partir da " : "
      const tratamento1 = tratamento.split(':');
      // transformando os dados em string
      const tratamento2 = tratamento1.toString();
      // quebrando os dados em array a partir da " , "
      const tratamento3 = tratamento2.split(',');

      const user = tratamento3[5].replace(/"/g, ' ');

      navigate(tratamento3[27] > 0 ? '/graficos' : '/Pedidos');

      if (user) {
        localStorage.setItem('user', user);
      }
      // passando apenas o idrep para o localStorage
      localStorage.setItem('idrep', tratamento3[21]);
      localStorage.removeItem('abc');
      localStorage.setItem('id_gerente', tratamento3[27]);
      localStorage.setItem('tp_user', tratamento3[15]);
      localStorage.setItem(
        'cor',
        randomColor({
          luminosity: 'dark',
        }),
      );
      notifylogin();

      const tpuser = JSON.parse(localStorage.getItem('tp_user'));

      if (tpuser === 4) {
        localStorage.setItem('id_gerente', '7');
      }
    } else {
      localStorage.setItem('abc', true);
    }
  };

  // trigger logout
  const logout = () => {
    // quando clicado em logado, seta o usuario como nulo e apaga os dados do localStorage
    LimparDados();
    setUser(null);
    navigate('/');
    notify();
  };

  return (
    <AuthContext.Provider
      value={{ authenticated: !!user, user, login, logout, loading }}
    >
      {children}
    </AuthContext.Provider>
  );
};
