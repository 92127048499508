import './ConsultaItem.css';
import { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Button, Table } from 'react-bootstrap';
import { ClipLoader } from 'react-spinners';
import api from '../../../services/api/axios';
import { Container, LeftIcon } from './styles';

function ConsultaItem() {
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fundo, setFundo] = useState('none');
  // importando o endpoint da URL
  const { id } = useParams();

  const nave = useNavigate();

  // contar o numero de itens que tem na planilha
  let contador = 1;

  // Procurando na API detalhes conforme o parametro
  useEffect(() => {
    api.get(`DetalhesPedidoItens?pedido=${id}`).then(async (resultado) => {
      setDados(resultado.data);
      if (resultado.data.length > 0) {
        setLoading(false);
        setFundo('');
      }
    });
  }, []);

  const somaTotalValor = dados
    .map((item) => item.PRECOUNITARIO * item.QUANTIDADE)
    .reduce((prev, curr) => prev + curr, 0);

  return (
    <Container>
      {dados.length > 0 ? (
        <div className="mainsearch" style={{ padding: '20px' }}>
          <Table striped>
            <thead>
              <tr>
                <th>Item</th>
                <th>Reduzido</th>
                <th>Descrição</th>
                <th>U.M</th>
                <th>Largura</th>
                <th>Quantidade</th>
                <th>Val. Unit</th>
                <th>Total</th>
                <th>Observação</th>
              </tr>
            </thead>
            <tbody>
              {/* mapeando os dados recebidos da api */}
              {dados.map((dados) => (
                <tr key={dados.REDUZIDO}>
                  <td>{contador++}</td>
                  <td>{dados.REDUZIDO}</td>
                  <th scope="row">{dados.DESCRICAO}</th>
                  <td>{dados.DESCR_UM}</td>
                  <td>{dados.LARGURA}</td>
                  <td>{dados.QUANTIDADE.toFixed(2).replace('.', ',')}</td>
                  {/* Formatando o valor para Nacional */}
                  <th>
                    {dados.PRECOUNITARIO.toLocaleString('pt-Br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </th>
                  <td>
                    {(dados.PRECOUNITARIO * dados.QUANTIDADE).toLocaleString(
                      'pt-Br',
                      {
                        style: 'currency',
                        currency: 'BRL',
                      },
                    )}
                  </td>
                  <td>{dados.OBS}</td>
                </tr>
              ))}
              <tr style={{ textAlign: 'end' }}>
                <th colSpan={7}>TOTAL: </th>
                <th colSpan={2}>
                  {somaTotalValor.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </th>
              </tr>
            </tbody>
          </Table>
          <div className="orderitem__buton">
            <Button
              type="button"
              id="button-addon2"
              variant="dark"
              onClick={() => nave(`/consultapage/${id}`)}
              style={{ width: '120px', height: '40px' }}
            >
              <LeftIcon />
            </Button>
            {/* <Link to={`/consultapage/${id}`}>
              <button type="button" className="button__orderdat">
                <i className="fa fa-arrow-circle-left" /> Dados do Pedido
              </button>
            </Link> */}
          </div>
        </div>
      ) : null}
      <div
        style={{
          width: '100%',
          display: loading ? 'flex' : 'none',
          justifyContent: 'center',
        }}
      >
        <ClipLoader
          type="spin"
          color="black"
          size={50}
          className="loading"
          speedMultiplier={1.2}
        />
      </div>
    </Container>
  );
}

export default ConsultaItem;
