/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable block-scoped-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './novoitem.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import CurrencyInput from 'react-currency-input-field';
import DatePicker, { registerLocale } from 'react-datepicker';
import pt from 'date-fns/locale/pt';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('pt', pt);

function NovoItem() {
  const mndate = new Date();
  mndate.setDate(mndate.getDate() + 15);

  const [startDate, setStartDate] = useState(mndate);

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      type="button"
      className="calendar__button"
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  ));

  function dataAtualFormatada(data) {
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear();
    return `${ano}${mes}${dia}`;
  }

  // formatação do produto
  function FormataProd(pCodComercial) {
    const tipo = pCodComercial.substr(0, 2);
    const artigo = pCodComercial.substr(2, 6);
    const rev = pCodComercial.substr(8, 2);
    const cor = pCodComercial.substr(13, 5);
    const des = pCodComercial.substr(18, 4);
    const vari = pCodComercial.substr(22, 1);

    return `${tipo}.${artigo}.${rev}.${cor}.${des}.${vari}`;
  }

  // pegar os itens salvos no localstorage
  const LocalItensDoPedido = JSON.parse(localStorage.getItem('jsonitens'));

  const ItensDoPedido =
    localStorage.getItem('jsonitens') !== null ? LocalItensDoPedido : [];

  const prodselect = JSON.parse(localStorage.getItem('prods'));

  const history = useNavigate();

  // let reddescr = "";
  let reduz = '';
  let descricao = '';
  let larg = '';
  let descrum = '';
  let um = '';
  let codcom = '';
  let idum = 0;
  let corHEX = '';
  let cor = '';
  let idumpadrao = 0;

  // selecionar os itens dentro da array
  if (prodselect) {
    reduz = prodselect[0];
    descricao = prodselect[1];
    larg = prodselect[3];
    codcom = prodselect[2];
    corHEX = prodselect[7];
    cor = prodselect[8];
    idumpadrao = prodselect[6];
  }

  const describe = localStorage.getItem('descrum');
  if (describe) {
    const arrayun = describe.split(' ');
    descrum = describe;
    um = arrayun[0];
    if (um === 'KG') {
      idum = 1;
    }
    if (um === 'M') {
      idum = 3;
    }
  }

  const [QTDE, setQTDE] = useState();
  const [QTDEFLOAT, setQTDEFLOAT] = useState();
  const [PRECO, setPRECO] = useState();
  const [PRECOFLOAT, setPRECOFLOAT] = useState();
  const [TOTAL, setTOTAL] = useState();
  const [OBS, setOBS] = useState('');
  const [valida, setValida] = useState(false);
  const [tipoum, setTipoum] = useState(false);

  function Retorno() {
    history('/Itensdopedido');
  }
  // função de adicionar item ao LocalStorage
  function AdicionarItem() {
    // validar qtdes
    if (descrum ? descrum.length > 0 : null) {
      if (QTDE && PRECO) {
        // criar um item do pedido
        const itemselecionado = {
          REDUZIDO: reduz,
          DESCRICAO: descricao,
          COD_COM: codcom,
          SIGLA_UM: um,
          DESCR_UM: descrum,
          LARGURA: larg,
          ID_UM_PADRAO: idumpadrao,
          QTDE,
          PRECO,
          OBS,
          ID_UM: idum,
          EXPEDIREM:
            localStorage.getItem('dtentrega') === null
              ? dataAtualFormatada(startDate)
              : localStorage.getItem('dtentrega'),
          NAO_ENTREGAR_ANTES: document.getElementById('flexCheckDefault')
            .checked
            ? 1
            : 0,
        };

        // adicionar um item a lista de iten do pedido
        ItensDoPedido.push(itemselecionado);
        localStorage.setItem('jsonitens', JSON.stringify(ItensDoPedido));

        // redirecionar para lista dos pedidos
        history('/Itensdopedido');
      } else {
        setValida(true);
      }
    } else {
      setTipoum(true);
    }
  }

  function CalcularQTDE() {
    const quantidade = QTDE;
    const preco = PRECO;
    if (quantidade) {
      const quantidaderepl = quantidade.replace(',', '.');
      var quantFloat = parseFloat(quantidaderepl);
    }
    if (preco) {
      const precorepl = preco.replace(',', '.');
      var precFloat = parseFloat(precorepl);
    }
    if (quantidade) {
      var n1 = quantidade.replace(',', '.');
    }
    if (preco) {
      var n2 = preco.replace(',', '.');
    }
    const resultado = n1 * n2;
    const resultadoModificado = resultado.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
    setTOTAL(resultadoModificado);
    setQTDEFLOAT(quantFloat);
    setPRECOFLOAT(precFloat);
  }

  const prefix = 'R$ ';
  const prefix2 = ` ${um} `;

  return (
    <div className="containerMain">
      <div className="newItemMain">
        <div className="newItemHead">
          <div>
            {/* definindo a cor do icone, baseado na seleção */}
            <i className="fa fa-archive" style={{ color: corHEX }} />
          </div>
          <div className="newItemHead__strings">
            <h4>Produto Selecionado: {descricao}</h4>
            <p>Largura: {larg}</p>
            <p>{`${reduz} - ${FormataProd(codcom)}`}</p>
            <select
              onChange={(e) => {
                localStorage.setItem('descrum', e.target.value);
              }}
              style={{
                color: 'red',
                outline: 'none',
                border: '0 none',
                fontWeight: 'bolder',
                fontSize: '16px',
              }}
            >
              <option value="" data-default disabled selected>
                U.M
              </option>
              <option value="KG - QUILOGRAMA">KG - QUILOGRAMA</option>
              <option value="M - METRO">M - METRO</option>
            </select>
            <h4 style={{ color: corHEX }} className="letterStroke">
              {cor}
            </h4>
          </div>
        </div>
        <div>
          <div>
            <div className="baias">
              <span className="baiasteste" id="inputGroup-sizing-sm">
                Quantidade:
              </span>
              <CurrencyInput
                id="input-example1"
                name="input-name1"
                placeholder="Insira a Quantidade"
                decimalsLimit={2}
                onValueChange={(value) => {
                  setQTDE(value);
                }}
                onBlur={CalcularQTDE}
                prefix={prefix2}
                onChange={localStorage.setItem('qtde-it', QTDEFLOAT)}
                inputType="number"
                step={1}
              />
            </div>
            <div className="baias">
              <span className="baiasteste" id="inputGroup-sizing-sm">
                Valor Unitário:{' '}
              </span>
              <CurrencyInput
                id="input-example"
                name="input-name"
                placeholder="Insira o Valor"
                decimalsLimit={2}
                onValueChange={(value) => setPRECO(value)}
                onChange={localStorage.setItem('valunit', PRECOFLOAT)}
                onBlur={CalcularQTDE}
                prefix={prefix}
                inputType="number"
              />
            </div>
            <div className="baias">
              <span className="baiasteste" id="inputGroup-sizing-sm">
                Total do Item:
              </span>
              <input
                type="text"
                className="baiasteste"
                value={TOTAL}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="baias">
          <span className="entregarate" id="mar-dir">
            <strong>Entregar até: </strong>{' '}
          </span>
          <div className="entregarate" id="mar-dir">
            <span>
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  localStorage.setItem('dtentrega', dataAtualFormatada(date));
                }}
                locale="pt"
                dateFormat="dd/MM/yyyy"
                customInput={<CustomInput />}
                minDate={mndate}
              />
            </span>
            <div className="naoentregar">
              <div>
                <div />
              </div>
              <div className="naoentregar">
                <input
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  onClick={() => {
                    document.getElementById('flexCheckDefault').checked
                      ? (document.getElementById('NaoEntregAntes').style.color =
                          'tomato')
                      : (document.getElementById('NaoEntregAntes').style.color =
                          '#8b8b8b');
                  }}
                />
                <label id="NaoEntregAntes" htmlFor="flexCheckDefault">
                  Não entregar antes da data solicitada
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="baias">
          <div className="entregarate">
            <label htmlFor="exampleFormControlTextarea1">
              Observações do Item:
            </label>
            <textarea
              id="exampleFormControlTextarea1"
              rows="3"
              onChange={(e) => setOBS(e.target.value)}
              onBlur={(e) => {
                localStorage.setItem('observit', e.target.value);
              }}
              defaultValue={localStorage.getItem('observit')}
            />
          </div>
        </div>
        <div className="orderitem__butons">
          <button
            type="button"
            onClick={AdicionarItem}
            className="button__addorder"
          >
            <i className="fa fa-cart-plus" />
            Adicionar
          </button>
          <button type="button" className="button__orderdata" onClick={Retorno}>
            <i className="fa fa-times" />
            Cancelar
          </button>
        </div>
      </div>
      {/* Alerta que falta Quantidade ou Preço para prosseguir */}
      {valida ? (
        <SweetAlert
          warning
          title="Aviso"
          customButtons={
            <button
              type="button"
              src="./itempedido.css"
              className="buttonalert__confirm"
              onClick={() => setValida(false)}
            >
              {' '}
              OK{' '}
            </button>
          }
        >
          Informe quantidade e preço.
        </SweetAlert>
      ) : null}
      {tipoum ? (
        <SweetAlert
          warning
          title="Aviso"
          customButtons={
            <button
              type="button"
              src="./itempedido.css"
              className="buttonalert__confirm"
              onClick={() => setTipoum(false)}
            >
              {' '}
              OK{' '}
            </button>
          }
        >
          Tipo de U.M invalido
        </SweetAlert>
      ) : null}
    </div>
  );
}

export default NovoItem;
