function LimparDados() {
  localStorage.removeItem('clicomp');
  localStorage.removeItem('clieentr');
  localStorage.removeItem('cliefat');
  localStorage.removeItem('condpgto');
  localStorage.removeItem('frmpgto');
  localStorage.removeItem('observ');
  localStorage.removeItem('observit');
  localStorage.removeItem('parcialdescr');
  localStorage.removeItem('pedrepres');
  localStorage.removeItem('pedcliente');
  localStorage.removeItem('prods');
  localStorage.removeItem('qtde-it');
  localStorage.removeItem('repres');
  localStorage.removeItem('temdtbase');
  localStorage.removeItem('tpfrete');
  localStorage.removeItem('tpped');
  localStorage.removeItem('transp');
  localStorage.removeItem('valunit');
  localStorage.removeItem('jsonitens');
  localStorage.removeItem('parcial');
  localStorage.removeItem('qntsentregas');
}

export default LimparDados;
