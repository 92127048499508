/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HashLoader from 'react-spinners/PulseLoader';
import api from '../../services/api/axios';

function Duplicarpe() {
  const [dados, setDados] = useState([]);
  const [itenspedido, setItenspedido] = useState([]);
  const nave = useNavigate();
  const itens = [];
  const { id } = useParams();

  useEffect(() => {
    api.get(`DetalhesPedido?pedido=${id}`).then(async (resultado) => {
      setDados(resultado.data);
    });
    api.get(`DetalhesPedidoItens?pedido=${id}`).then(async (resultado) => {
      setItenspedido(resultado.data);
    });
  }, []);

  function toNumberString(num) {
    if (Number.isInteger(num)) {
      return `${num}.0`;
    }
    return num.toString();
  }

  // setando os dados da api no localstorage
  dados.map((data) => {
    localStorage.setItem(
      'clicomp',
      JSON.stringify([data.IDFILIALCOBRANCA, data.CNPJ, data.CLIENTE]),
    );
    localStorage.setItem(
      'clieentr',
      JSON.stringify([data.IDFILIALENTREGA, data.CNPJ_ENTR, data.CLIENTE_ENTR]),
    );
    localStorage.setItem(
      'cliefat',
      JSON.stringify([
        data.IDFILIALRESPONSAVEL,
        data.CNPJ_RESP,
        data.CLIENTE_RESP,
      ]),
    );
    localStorage.setItem(
      'repres',
      JSON.stringify([data.IDREPRESENTANTE, data.REPRESENTANTE, data.COMISSAO]),
    );
    localStorage.setItem('tpped', JSON.stringify(data.TIPOPEDIDO));
    localStorage.setItem('frmpgto', JSON.stringify(data.CDFORMPAGA));
    localStorage.setItem(
      'condpgto',
      JSON.stringify([
        data.CONDICAO_PAGAMENTO,
        data.DESCR_COND_PAGTO,
        data.PARCELAS,
      ]),
    );
    localStorage.setItem(
      'transp',
      JSON.stringify([data.IDREDESPACHO, ' ', data.TRANSPORTADORA]),
    );
    localStorage.setItem('tpfrete', JSON.stringify(data.TIPOFRETE));
    localStorage.setItem('parcial', data.PARCIAL);
    if (data.PARCIAL === '1') {
      localStorage.setItem('qntsentregas', '2 Entregas');
    }
  });

  // criar um item no pedido
  itenspedido.map((it) => {
    const itemselecionado = {
      REDUZIDO: it.REDUZIDO,
      DESCRICAO: it.DESCRICAO,
      COD_COM: it.CODIGO_COMERCIAL,
      SIGLA_UM: it.SIGLA_UM,
      DESCR_UM: it.DESCR_UM,
      LARGURA: it.LARGURA,
      ID_UM_PADRAO: it.ID_UM,
      QTDE: toNumberString(it.QUANTIDADE_DIGITACAO),
      PRECO: toNumberString(it.PRECOUNITARIO),
      OBS: it.OBS,
      ID_UM: it.IDUMDIGITACAO,
      EXPEDIREM: it.EXPEDIREM,
      NAO_ENTREGAR_ANTES: it.NAO_ENTREGAR_ANTES,
    };
    // adicionar um item a lista de itens do pedido
    return itens.push(itemselecionado);
  });

  localStorage.setItem('jsonitens', JSON.stringify(itens));

  setTimeout(() => nave('/Addpedido'), 2000);

  return (
    <div className="loading">
      <p style={{ marginRight: '10px' }}>
        Carregando o Pedido <strong>{id}</strong>
      </p>
      <HashLoader type="spin" color="black" size={10} speedMultiplier={0.9} />
    </div>
  );
}

export default Duplicarpe;
